import React, { useCallback, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify'
import api from '../../helpers/api'
import { makePath } from '../../helpers/path'
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import LoadingLayout from '../Layout/LoadingLayout';

interface PGSlotResponse {
	token: string
	url: string
}
const PGSlotGame = () => {
	const history = useHistory()
	const location = useLocation();
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const initPGSlotGame = useCallback(async () => {
		try {
			const { token, url } = await api.post<void, PGSlotResponse>('/login/pgslot')

			if (url) {
				const params = new URLSearchParams(location.search);
				params.set('path', '/wallet')
				history.push({ search: params.toString() });
				window.location.href = url
			} else {
				const params = new URLSearchParams(location.search);
				params.set('path', '/wallet')
				history.push({ search: params.toString() });
				window.location.href = `https://pgsgame.games/games/index.html?t=${token}`
			}
		} catch ({ message }: any) {
			toast.error(message)
			history.push(makePath('/wallet'))
		}
	}, [history]) //eslint-disable-line

	// set provider to url
	// useEffect(() => {
	// 	const params = new URLSearchParams(location.search);
	// 	params.set('path', '/wallet')
	// 	history.push({ search: params.toString() });
	// }, [history, location.search]);


	// get query string
	// useEffect(() => {
	// 	const params = new URLSearchParams(location.search);
	// 	const path = params.get('path');
	// 	if (path) {
	// 		history.push(makePath('/wallet'))
	// 	}
	// }, [history, location.search]);

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const path = params.get('path');

		if (!path) {
			initPGSlotGame()
		} else {
			setTimeout(() => {
				history.push(makePath('/wallet'))
			}, 1000);
		}
	}, [initPGSlotGame, history, location.search])

	// return <div>Redirecting ...</div>
	return (
		<LoadingLayout prefix={prefixState} />
	)
}

export default PGSlotGame
