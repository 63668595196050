import React, { FC, useState } from 'react'
import { AMBKingGameProviderConfig } from '../../../entities/AMBKing'
import { useHistory, useLocation } from 'react-router-dom';

interface ImageProps {
    src: string;
    alt: string;
    className?: string;
    placeholderSrc: string;
}

const ImageWithPlaceholder: FC<ImageProps> = ({ src, alt, className, placeholderSrc }) => {
    const [imageSrc, setImageSrc] = useState<string | null>(src);

    const handleImageError = () => {
        // ถ้ารูปโหลดไม่ได้ ให้ใช้รูปทดแทน
        setImageSrc(placeholderSrc);
    };

    return (
        <img
            src={imageSrc as string}
            alt={alt}
            className={className}
            onError={handleImageError}
        />
    );
};

interface ProviderListProps {
    config: Record<string, AMBKingGameProviderConfig> | any
    type: string

    setProvider: (name: string) => void
    setGameType: (name: string) => void
}

const ProviderList: FC<ProviderListProps> = ({ config, type, setProvider, setGameType }) => {

    const location = useLocation();
    const history = useHistory();

    const setDeleteGameFilter = () => {
        const params = new URLSearchParams(location.search);
        params.delete('gameFilter');
        history.push({ search: params.toString() });
    }

    return (
        <div className="ambking-grid-row">
            {Object.values(config).map((itemProvider: any, indexProvider: any) => (
                (itemProvider.games && itemProvider.games.length > 0 && Object.values(itemProvider.games).filter((item: any) => item.status === 'ACTIVE').length) && (
                    <div
                        key={`${itemProvider.provider}-${indexProvider}`}
                        className="game-card"
                        onClick={() => {
                            setProvider(itemProvider.provider)
                            setGameType(type)
                            setDeleteGameFilter()
                        }}
                    >
                        <div className="game-card-img-container">
                            {((type === 'card' && (itemProvider.provider === 'WOW' || itemProvider.provider === 'AI')) || (type === 'slot' && (itemProvider.provider === 'WOWS'))) ?
                                <ImageWithPlaceholder src={`/provider-cover/${type}/${itemProvider.provider.toLowerCase()}.webp`} alt={itemProvider.providerName} className="game-card-logo" placeholderSrc="/image-placeholder.png" />
                                :
                                <ImageWithPlaceholder src={`/provider-cover/${type}/${itemProvider.provider.toLowerCase()}.webp`} alt={itemProvider.providerName} className="game-card-img" placeholderSrc="/image-placeholder.png" />
                            }
                            <span className="game-card-name">{itemProvider.providerName}</span>
                        </div>
                    </div>
                )
            ))}
        </div>
    )
}

export default ProviderList
