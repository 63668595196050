import React, { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Member } from '../../@types/member'
import { Prefix } from '../../@types/prefix'
import { topbarText } from '../../constant'
import { makePath } from '../../helpers/path'
import TopbarWidget from './TopbarWidget'
import { Avatar, Typography } from 'antd';

const { Title } = Typography;

interface TopbarProps {
	prefix?: Prefix
	member?: Member
	setIsToggleSound: (e: any) => void
	pathname: string
}
const Topbar: FC<TopbarProps> = ({ prefix, member, pathname, setIsToggleSound }) => {
	const loc = useLocation()

	return (
		<>
			<div className={`topbar ${pathname === '/wallet' || pathname === '/launch-game' ? '' : 'topbar-hidden'}`}>
				<div className="web-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div className="topbar-logo">
						<Link to={makePath('/wallet')}>
							{prefix?.logo ? (
								<Avatar
									className="avatar-logo avatar-logo-topbar"
									src={prefix?.logo}
									shape="square"
								/>
							) : (
								<div className="prefix-name">{prefix?.prefix}</div>
							)}
						</Link>
					</div>
					<div className="topbar-welcome">
						<Title level={4} style={{ margin: 0 }}>{topbarText[loc.pathname] ?? `กระเป๋า`}</Title>
					</div>

					<TopbarWidget prefix={prefix} member={member} setIsToggleSound={setIsToggleSound} />
				</div>
			</div>
		</>
	)
}

export default Topbar
