import React, { FC, useEffect, useState } from 'react'
import useWebSocket from 'react-use-websocket';
import { getDomainWithoutSubdomain } from './domainWithoutSubdomain';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Typography } from 'antd';
import { Link as LinkDom } from 'react-router-dom'
import { makePath } from '../helpers/path';
import { useDispatch } from 'react-redux';
import { requestUser } from '../store/user/user.actions';

const { Text } = Typography

interface NotifyWebSocketProp {
    setIsModalSocket: (e: any) => void
}

const NotifyWebSocket: FC<NotifyWebSocketProp> = ({ setIsModalSocket }) => {

    const dispatch = useDispatch()

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isMessage, setIsMessage] = useState<any>('')

    let SocketUrlEndPoint = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : ""

    if (window.location.hostname !== 'localhost' && window.location.hostname !== "fcn.bet") {
        SocketUrlEndPoint = `https://api.${getDomainWithoutSubdomain(window.location.host)}/v2`
    }
    const SocketUrl = (SocketUrlEndPoint).replace("http://", "ws://").replace(
        "https://",
        "wss://"
    );

    const token = localStorage.getItem('x-auth');
    const socketUrl = `${SocketUrl}/member/ws?token=${token}`;
    const { lastMessage, readyState, sendJsonMessage } = useWebSocket(socketUrl);

    const reBalance = () => {
        dispatch(requestUser())
    }

    useEffect(() => {
        if (lastMessage !== null) {
            if (lastMessage.data !== "PONG") {
                const notify = JSON.parse(lastMessage.data);
                setIsModalSocket(false)
                reBalance()
                setIsMessage(notify.Message)
                setIsOpen(true)
                // toast(notify.Message, {
                //     type: notify.Type === "danger" ? "error" : notify.Type,
                //     autoClose: 5000
                // })
            }
        }
    }, [lastMessage]) // eslint-disable-line

    useEffect(() => {
        const pingInterval = setInterval(() => {
            if (readyState === WebSocket.OPEN) {
                sendJsonMessage({ type: "PING" });
            }
        }, 15000);

        return () => {
            clearInterval(pingInterval);
        };
    }, [readyState, sendJsonMessage]); // eslint-disable-line

    return (
        <>
            {/* Step 2 */}
            <Modal
                className="p2p-modal"
                open={isOpen}
                footer={null}
                onCancel={() => { setIsOpen(false); }}
                centered>
                <div style={{ textAlign: 'center', padding: '24px 0' }}>
                    <CheckCircleOutlined rev={undefined} style={{ color: '#47e15f', fontSize: 56, marginBottom: 16 }} />
                    <div style={{ marginBottom: 12 }}>
                        <Text>{isMessage}</Text>
                        <Text>อัพเดทยอดเงินเรียบร้อย</Text>
                    </div>
                    <LinkDom to={makePath('/wallet')}>
                        <Button type="primary" onClick={() => { setIsOpen(false); }}>กลับหน้ากระเป๋า</Button>
                    </LinkDom>
                </div>
            </Modal>
        </>
    )
}

export default NotifyWebSocket