import React, { FC } from 'react'
import Marquee from 'react-fast-marquee';
import { useLocation } from 'react-router-dom';

interface Props {
    text: string
}
const Notice: FC<Props> = ({ text }) => {
    const loc = useLocation();
    const isFUllNotice = loc.pathname === "/launch-game" || loc.pathname === "/home";

    return (
        <div className={`notice-wrapper notice-full`}>
            <div className="notice-icon">
                <i className="fa-duotone fa-bullhorn"></i>
            </div>
            <div className="notice-text">
                <Marquee pauseOnHover gradient={false}>{text}</Marquee>
            </div>
        </div>
    )
}

export default Notice
