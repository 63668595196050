import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import { IPGOONGame } from '../../entities/PGOONType'
import { toast } from 'react-toastify'
import api from '../../helpers/api'
import { makePath } from '../../helpers/path'
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import LoadingLayout from '../Layout/LoadingLayout';
import SeoContent from '../Layout/SeoContent';
import Footer from '../Layout/Footer';
import Popup from '../Popup';
import { Empty } from 'antd';
import MainSlider from '../Wallet/MainSlider';
import GameList from './PGOON/GameList';
import CheckInModal from '../CheckIn/CheckInModal';

interface PGOONResponse {
	token: string
	url: string
}
const PGOONGame = () => {
	const location = useLocation();
	const history = useHistory();

	const [config, setConfig] = useState<IPGOONGame[] | any>([])
	const [provider, setProvider] = useState('')
	const [pathName, setPathName] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(true)

	const providerState = useSelector((state: ApplicationState) => state.provider)
	const providerHomeState = useSelector((state: ApplicationState) => state.gamesHome)
	const prefixState = useSelector((state: ApplicationState) => state.prefix)

	useEffect(() => {
		if (providerState?.info !== undefined && location.pathname === '/launch-game') {
			setConfig(providerState?.info)
			// setAllProvider(providerState?.info?.games)
			// if (Object.values(providerState?.info?.games).length === 1) {
			//     setGameType(Object.keys(providerState?.info?.games)[0]);
			// }

			// setLoadingAMBKing(true)
		}

		if (providerHomeState?.info !== undefined && location.pathname === '/home') {
			setConfig(providerHomeState?.info)
			// setAllProvider(providerHomeState?.info?.games)
			// if (Object.values(providerHomeState?.info?.games).length === 1) {
			//     setGameType(Object.keys(providerHomeState?.info?.games)[0]);
			// }

			// setLoadingAMBKing(true)
		}

		setPathName(location.pathname === '/launch-game' ? 'launch-game' : 'home')

		setLoading(false)
	}, [providerState?.info, providerHomeState?.info, location]) // eslint-disable-line
	// console.log(config);

	return (
		<div className="ambking-container">
			<MainSlider prefixState={prefixState} />

			<div className="ant-tabs ant-tabs-top ambking-new-tab" style={{ marginTop: 12 }}>
				{/* <div className="ant-tabs-nav">
                    <div className="ant-tabs-nav-list">
                        <div className={`ant-tabs-tab ${gameType === 'favorite' ? 'ant-tabs-tab-active' : ''}`} key={`menu-tab-${`favorite`}`} onClick={() => { location.pathname === '/launch-game' ? handleGameType('favorite') : history.push(makePath('/login')) }}
                        >
                            <div className="ant-tabs-tab-btn">
                                <div className="menu-tab">
                                    <div className="icon">
                                        <img src="/icons/3d/favorite.svg" alt="nav" />
                                    </div>
                                    <span>
                                        <strong>เกมโปรด</strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {TabGameType}
                    </div>
                </div> */}
				<div className="ambking-tab">
					{loading ? (
						<LoadingLayout prefix={prefixState} />
					) : (
						config?.length > 0 ? (
							<div key={`games-slot`}>
								<GameList
									games={config}
									// gameType={'slot'}
									loadingProvider={loading}
									setLoading={setLoading}
									pathName={pathName}
								/>
							</div>
						) : (
							<div style={{ display: 'flex', width: '100%', marginTop: '12px' }}>
								<Empty
									description='ไม่พบเกมส์'
									image="/icons/new/empty-box.svg"
									imageStyle={{ height: 88 }}
									style={{ flex: 'auto', marginTop: 24 }}
								/>
							</div>
						)
					)}
				</div>
			</div>

			{location.pathname === '/home' && <SeoContent />}

			<Footer prefixState={prefixState} />

			{/* Popup */}
			<Popup />

			{/* CheckIn */}
			{prefixState.info?.checkin.enable && <CheckInModal />}
		</div>
	)
}

export default PGOONGame
