import React, { FC } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Member } from '../../@types/member'
import { Prefix } from '../../@types/prefix'
import { topbarText } from '../../constant'
import { makePath } from '../../helpers/path'
import { Avatar, Button, Typography } from 'antd';

const { Title } = Typography;

interface TopbarProps {
	prefix?: Prefix
	member?: Member

	pathname: string
}
const Topbar: FC<TopbarProps> = ({ prefix, member, pathname }) => {
	const loc = useLocation()
	const history = useHistory()

	return (
		<>
			<div className="topbar topbar-home">
				<div className="web-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
					{pathname === '/home' && (
						<div className="topbar-logo">
							<Link to={makePath('/home')}>
								{prefix?.logo ? (
									<Avatar
										className="avatar-logo avatar-logo-topbar"
										src={prefix?.logo}
										shape="square"
									/>
								) : (
									<div className="prefix-name">{prefix?.prefix}</div>
								)}
							</Link>
						</div>
					)}

					<div className="topbar-member">
						<Button
							className="ant-btn-secondary"
							style={{ display: 'block' }}
							onClick={() => history.push(makePath('/login'))}
						>
							เข้าสู่ระบบ
						</Button>
						{(!prefix?.functions.hasOwnProperty("offRegister") || !prefix?.functions['offRegister']) && (
							<Button
								className="ant-btn-tertiary"
								style={{ marginLeft: 12, display: 'block' }}
								onClick={() => history.push(makePath('/register'))}
							>
								สมัครสมาชิก
							</Button>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default Topbar
