export const Games = [
    {
        "id": "66faa5bbb9410ad3acc1cb74",
        "provider": "PGS",
        "gameName": "Oishi Delights",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Oishi%20Delights_1728032208.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Oishi%20Delights_1728032210.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 91.47773865,
        "gameCode": 1815268
    },
    {
        "id": "66e9480d11711a7813b76f70",
        "provider": "PGS",
        "gameName": "Three Crazy Piggies",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Three%20Crazy%20Piggies_1726669980.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Three%20Crazy%20Piggies_1726669984.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.11803465,
        "gameCode": 1727711
    },
    {
        "id": "66c31a41c0e45bdc2c487abb",
        "provider": "PGS",
        "gameName": "Wings of Iguazu",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Wings%20of%20Iguazu_1724657922.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Wings%20of%20Iguazu_1724657950.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.73742923,
        "gameCode": 1747549
    },
    {
        "id": "66b0bd9269917b030d307754",
        "provider": "PGS",
        "gameName": "Yakuza Honor",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Yakuza%20Honor_1722858906.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Yakuza%20Honor_1722858908.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.15394995,
        "gameCode": 1760238
    },
    {
        "id": "6697b178dbf56c32f9bba3bd",
        "provider": "PGS",
        "gameName": "Shark Hunter",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Shark%20Hunter_1722496275.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Shark%20Hunter_1722496277.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.01382401,
        "gameCode": 1648578
    },
    {
        "id": "6683b510e4f57cf3ca5587df",
        "provider": "PGS",
        "gameName": "Futebol Fever",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Futebol%20Fever_1719907604.jpg",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Futebol%20Fever_1719907606.jpg",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.82457725,
        "gameCode": 1778752
    },
    {
        "id": "667c182c6efb8b7700d52958",
        "provider": "PGS",
        "gameName": "Chicky Run",
        "gameCategory": "SLOT",
        "gameType": [
            "ARCADE"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Chicky%20Run_1719409810.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Chicky%20Run_1719409910.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 92.69852423,
        "gameCode": 1738001
    },
    {
        "id": "6662eb520f574f61a12088d2",
        "provider": "PGS",
        "gameName": "Zombie Outbreak",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Zombie%20Outbreak_1717758833.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Zombie%20Outbreak_1717758836.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.96268472,
        "gameCode": 1635221
    },
    {
        "id": "6654862f0f574f61a120875d",
        "provider": "PGS",
        "gameName": "Anubis Wrath",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Anubis%20Wrath_1716881055.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Anubis%20Wrath_1716881052.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 93.79365913,
        "gameCode": 1623475
    },
    {
        "id": "663a061680881500eb5d45e2",
        "provider": "PGS",
        "gameName": "Mystic Potion",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Mystic%20Potion_1715152666.jpg",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Mystic%20Potion_1715152676.jpg",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.78975244,
        "gameCode": 1717688
    },
    {
        "id": "66150caa11b82fa493bd2bd0",
        "provider": "PGS",
        "gameName": "Pinata Wins",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Pinata%20Wins_1712661193.jpg",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Pinata%20Wins_1712661199.jpg",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.48243661,
        "gameCode": 1492288
    },
    {
        "id": "660a7ca011b82fa493bd29e0",
        "provider": "PGS",
        "gameName": "Wild Ape #3258",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Wild%20Ape_1712050360.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Wild%20Ape_1712050369.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.91902545,
        "gameCode": 1508783
    },
    {
        "id": "65f2ac153b1bb0540ad1c1f4",
        "provider": "PGS",
        "gameName": "Cash Mania",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Cash%20Mania_1710746168.jpg",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Cash%20Mania_1710746174.jpg",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.91679994,
        "gameCode": 1682240
    },
    {
        "id": "65e591a6a8d4f9e78a2359cb",
        "provider": "PGS",
        "gameName": "Gemstones Gold",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Gemstones%20Gold_1709544215.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Gemstones%20Gold_1709544120.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.46921345,
        "gameCode": 1671262
    },
    {
        "id": "65b3f831397e61075c83fa4c",
        "provider": "PGS",
        "gameName": "Fortune Dragon",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Fortune%20Dragon_1706519943.jpg",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Fortune%20Dragon_1706519950.jpg",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.68971725,
        "gameCode": 1695365
    },
    {
        "id": "65a4dc05ce8220fa9ea9cbad",
        "provider": "PGS",
        "gameName": "Dragon Hatch 2",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Dragon%20Hatch%202_1705316931.jpg",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Dragon%20Hatch%202_1705316943.jpg",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.88790803,
        "gameCode": 1451122
    },
    {
        "id": "6597b7243ba2c4da5ec762c8",
        "provider": "PGS",
        "gameName": "Werewolf's Hunt",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Werewolf%27s%20Hunt_1704443009.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Werewolf%27s%20Hunt_1704443012.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.55394367,
        "gameCode": 1615454
    },
    {
        "id": "60531c5534d88c344ce9acbd",
        "provider": "PGS",
        "gameName": "Treasures of Aztec",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Treasures%20of%20Aztec_1702651933.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Treasures%20of%20Aztec_1702649286.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.52269053,
        "gameCode": 87
    },
    {
        "id": "60531c5534d88c344ce9acc4",
        "provider": "PGS",
        "gameName": "Lucky Neko",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Lucky%20Neko_1702651224.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Lucky%20Neko_1702648710.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.52848433,
        "gameCode": 89
    },
    {
        "id": "609e78ea4a67b994b50d2a66",
        "provider": "PGS",
        "gameName": "Wild Bandito",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Wild%20Bandito_1702651545.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Wild%20Bandito_1702649347.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.70051278,
        "gameCode": 104
    },
    {
        "id": "60531c5534d88c344ce9aca9",
        "provider": "PGS",
        "gameName": "Mahjong Ways",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Mahjong%20Ways_1702651252.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Mahjong%20Ways_1702648753.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.82669124,
        "gameCode": 65
    },
    {
        "id": "60531c5534d88c344ce9acb2",
        "provider": "PGS",
        "gameName": "Mahjong Ways 2",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Mahjong%20Ways%202_1702651260.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Mahjong%20Ways%202_1702648759.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.03414012,
        "gameCode": 74
    },
    {
        "id": "61af2980e5d289e90f512720",
        "provider": "PGS",
        "gameName": "Ways of the Qilin",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Ways%20of%20the%20Qilin_1702651533.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Ways%20of%20the%20Qilin_1702649340.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.52747877,
        "gameCode": 106
    },
    {
        "id": "657c6c5edc072093049ee230",
        "provider": "PGS",
        "gameName": "Tsar Treasures",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Tsar%20Treasures_1702653861.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Tsar%20Treasures_1702653858.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.8520681,
        "gameCode": 1655268
    },
    {
        "id": "65780764d5d85b2a27578ce8",
        "provider": "PGS",
        "gameName": "Mafia Mayhem",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Mafia%20Mayhem_1702653832.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Mafia%20Mayhem_1702653828.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.67631907,
        "gameCode": 1580541
    },
    {
        "id": "655b2079092bec2ac1ad65b1",
        "provider": "PGS",
        "gameName": "Forge of Wealth",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Forge%20of%20Wealth_1702650930.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Forge%20of%20Wealth_1702650924.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.14639073,
        "gameCode": 1555350
    },
    {
        "id": "65436a99ea98c0e66161decd",
        "provider": "PGS",
        "gameName": "Wild Heist Cashout",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Wild%20Heist%20Cashout_1699342200.jpg",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Wild%20Heist%20Cashout_1699270466.jpg",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.00684967,
        "gameCode": 1568554
    },
    {
        "id": "652e5e651bacfbd4c2908a83",
        "provider": "PGS",
        "gameName": "Ultimate Striker",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Ultimate%20Striker_1702649897.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Ultimate%20Striker_1702647026.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.83009915,
        "gameCode": 1489936
    },
    {
        "id": "651a86bcd9d5c7a2c1190b78",
        "provider": "PGS",
        "gameName": "Ninja Raccoon Frenzy",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Ninja%20Raccoon%20Frenzy_1702651330.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Ninja%20Raccoon%20Frenzy_1702647067.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.13716235,
        "gameCode": 1529867
    },
    {
        "id": "650ee22baf17ad9d265fc7e2",
        "provider": "PGS",
        "gameName": "Gladiator's Glory",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Gladiator%27s%20Glory_1702649928.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Gladiator%27s%20Glory_1702647089.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.29374003,
        "gameCode": 1572362
    },
    {
        "id": "64ee44fdc1e1e7de0d997015",
        "provider": "PGS",
        "gameName": "Safari Wilds",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Safari%20Wilds_1702649950.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Safari%20Wilds_1702647106.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.97115231,
        "gameCode": 1594259
    },
    {
        "id": "64d38696ce633aea332d4b4e",
        "provider": "PGS",
        "gameName": "Cruise Royale",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Cruise%20Royale_1702649966.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Cruise%20Royale_1702647124.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.33490832,
        "gameCode": 1473388
    },
    {
        "id": "64ad591bdf561b7a323e96fa",
        "provider": "PGS",
        "gameName": "Fruity Candy",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Fruity%20Candy_1702650995.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Fruity%20Candy_1702647146.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.69689355,
        "gameCode": 1397455
    },
    {
        "id": "648aa1f17b820f8f1e7995d3",
        "provider": "PGS",
        "gameName": "Lucky Clover Lady",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Lucky%20Clover%20Lady_1702650001.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Lucky%20Clover%20Lady_1702647169.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.48743708,
        "gameCode": 1601012
    },
    {
        "id": "646c6f827cd3acce7c334bb4",
        "provider": "PGS",
        "gameName": "Super Golf Drive",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Super%20Golf%20Drive_1702650015.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Super%20Golf%20Drive_1702647187.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.72876452,
        "gameCode": 1513328
    },
    {
        "id": "6449fd9cdc4b313d6a4b968f",
        "provider": "PGS",
        "gameName": "Mystical Spirits",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Mystical%20Spirits_1702651322.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Mystical%20Spirits_1702648901.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.88128187,
        "gameCode": 1432733
    },
    {
        "id": "63bfd5c622b9e6f28b091ce1",
        "provider": "PGS",
        "gameName": "Fortune Rabbit",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Fortune%20Rabbit_1702650982.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Fortune%20Rabbit_1702648059.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.65554324,
        "gameCode": 1543462
    },
    {
        "id": "6303327eebafc4fa8272f137",
        "provider": "PGS",
        "gameName": "Wild Bounty Showdown",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Wild%20Bounty%20Showdown_1702651563.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Wild%20Bounty%20Showdown_1702649370.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.62040752,
        "gameCode": 135
    },
    {
        "id": "641059fc299d6ec2bbd28c09",
        "provider": "PGS",
        "gameName": "Bakery Bonanza",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Bakery%20Bonanza_1702650054.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Bakery%20Bonanza_1702647374.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.49730219,
        "gameCode": 1418544
    },
    {
        "id": "60531c5534d88c344ce9acc9",
        "provider": "PGS",
        "gameName": "Fortune Ox",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Fortune%20Ox_1702650976.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Fortune%20Ox_1702648036.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.87832385,
        "gameCode": 98
    },
    {
        "id": "60531c5534d88c344ce9acaf",
        "provider": "PGS",
        "gameName": "Caishen Wins",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Caishen%20Wins_1702650093.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Caishen%20Wins_1702647545.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.41541409,
        "gameCode": 71
    },
    {
        "id": "60531c5534d88c344ce9acb4",
        "provider": "PGS",
        "gameName": "Ganesha Fortune",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Ganesha%20Fortune_1702651007.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Ganesha%20Fortune_1702648096.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.22429185,
        "gameCode": 75
    },
    {
        "id": "6433b5d52c6902344243cc6f",
        "provider": "PGS",
        "gameName": "Songkran Splash",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Songkran%20Splash_1702651441.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Songkran%20Splash_1702649169.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.61444537,
        "gameCode": 1448762
    },
    {
        "id": "6395de70f7320aee2573ae32",
        "provider": "PGS",
        "gameName": "Asgardian Rising",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Asgardian%20Rising_1702650041.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Asgardian%20Rising_1702647346.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.60298573,
        "gameCode": 1340277
    },
    {
        "id": "641059cc299d6ec2bbd28c08",
        "provider": "PGS",
        "gameName": "Hawaiian Tiki",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Hawaiian%20Tiki_1702651084.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Hawaiian%20Tiki_1702648312.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.60964806,
        "gameCode": 1381200
    },
    {
        "id": "63f3085fa338520cea6e1913",
        "provider": "PGS",
        "gameName": "Rave Party Fever",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Rave%20Party%20Fever_1702651395.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Rave%20Party%20Fever_1702649058.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.17920046,
        "gameCode": 1420892
    },
    {
        "id": "63b114809de1192ec5790dcc",
        "provider": "PGS",
        "gameName": "Midas Fortune",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Midas%20Fortune_1702651300.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Midas%20Fortune_1702648841.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.80946678,
        "gameCode": 1402846
    },
    {
        "id": "6370d0b3d3f541a01484de4b",
        "provider": "PGS",
        "gameName": "Diner Delights",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Diner%20Delights_1702650944.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Diner%20Delights_1702650940.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.17853806,
        "gameCode": 1372643
    },
    {
        "id": "6343f98165580ff060a42707",
        "provider": "PGS",
        "gameName": "Alchemy Gold",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Alchemy%20Gold_1702650033.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Alchemy%20Gold_1702647262.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.90408351,
        "gameCode": 1368367
    },
    {
        "id": "631728cd3ddeac8867580258",
        "provider": "PGS",
        "gameName": "Totem Wonders",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Totem%20Wonders_1702651508.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Totem%20Wonders_1702649276.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.93076488,
        "gameCode": 1338274
    },
    {
        "id": "630c6b3e35a342efac0ec906",
        "provider": "PGS",
        "gameName": "Prosperity Fortune Tree",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Prosperity%20Fortune%20Tree_1702651369.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Prosperity%20Fortune%20Tree_1702649005.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.86521071,
        "gameCode": 1312883
    },
    {
        "id": "62d6747a419ec630e268816b",
        "provider": "PGS",
        "gameName": "Wild Coaster",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Wild%20Coaster_1702651674.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Wild%20Coaster_1702649377.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.07409835,
        "gameCode": 132
    },
    {
        "id": "62cd2a6c5476ba8e1061c0a0",
        "provider": "PGS",
        "gameName": "Legend of Perseus",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Legend%20of%20Perseus_1702651200.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Legend%20of%20Perseus_1702648658.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.97698221,
        "gameCode": 128
    },
    {
        "id": "62b2c2d0f5f48ee7f4b25f2a",
        "provider": "PGS",
        "gameName": "Speed Winner",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Speed%20Winner_1702651988.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Speed%20Winner_1702649176.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.35281627,
        "gameCode": 127
    },
    {
        "id": "62a6f343ce8a365c3b00be62",
        "provider": "PGS",
        "gameName": "Lucky Piggy",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Lucky%20Piggy_1702651238.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Lucky%20Piggy_1702648717.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.01795854,
        "gameCode": 130
    },
    {
        "id": "628c6bb3b785c10a9714f687",
        "provider": "PGS",
        "gameName": "Win Win Fish Prawn Crab",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Win%20Win%20Fish%20Prawn%20Crab_1702652011.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Win%20Win%20Fish%20Prawn%20Crab_1702649427.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.54851817,
        "gameCode": 129
    },
    {
        "id": "60531c5534d88c344ce9acb1",
        "provider": "PGS",
        "gameName": "Bikini Paradise ",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Bikini%20Paradise_1702650074.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Bikini%20Paradise_1702647485.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.01221456,
        "gameCode": 69
    },
    {
        "id": "60531c5534d88c344ce9acb0",
        "provider": "PGS",
        "gameName": "Candy Burst ",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Candy%20Burst_1702650107.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Candy%20Burst_1702647584.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.73691131,
        "gameCode": 70
    },
    {
        "id": "60531c5534d88c344ce9aca3",
        "provider": "PGS",
        "gameName": "Captain's Bounty",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Captain%27s%20Bounty_1702650113.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Captain%27s%20Bounty_1702647639.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.39728435,
        "gameCode": 54
    },
    {
        "id": "60531c5534d88c344ce9acbe",
        "provider": "PGS",
        "gameName": "Circus Delight",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Circus%20Delight_1702650119.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Circus%20Delight_1702647663.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.7216056,
        "gameCode": 80
    },
    {
        "id": "60531c5534d88c344ce9ac83",
        "provider": "PGS",
        "gameName": "Honey Trap of Diao Chan",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Honey%20Trap%20of%20Diao%20Chan_1702651102.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Honey%20Trap%20of%20Diao%20Chan_1702648355.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 99.63646817,
        "gameCode": 1
    },
    {
        "id": "60531c5534d88c344ce9aca0",
        "provider": "PGS",
        "gameName": "Double Fortune",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Double%20Fortune_1702650161.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Double%20Fortune_1702647778.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.8054573,
        "gameCode": 48
    },
    {
        "id": "60531c5534d88c344ce9acaa",
        "provider": "PGS",
        "gameName": "Dragon Hatch",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Dragon%20Hatch_1702650172.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Dragon%20Hatch_1702647793.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.7253204,
        "gameCode": 57
    },
    {
        "id": "60531c5534d88c344ce9ac92",
        "provider": "PGS",
        "gameName": "Dragon Legend",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Dragon%20Legend_1702650179.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Dragon%20Legend_1702647824.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.63358077,
        "gameCode": 29
    },
    {
        "id": "60531c5534d88c344ce9aca8",
        "provider": "PGS",
        "gameName": "Dragon Tiger Luck",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Dragon%20Tiger%20Luck_1702650190.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Dragon%20Tiger%20Luck_1702647848.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.10018706,
        "gameCode": 63
    },
    {
        "id": "60531c5534d88c344ce9acb7",
        "provider": "PGS",
        "gameName": "Dreams of Macau",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Dreams%20of%20Macau_1702650198.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Dreams%20of%20Macau_1702647871.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.81742355,
        "gameCode": 79
    },
    {
        "id": "60531c5534d88c344ce9acb3",
        "provider": "PGS",
        "gameName": "Egypt's Book of Mystery",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Egypt%27s%20Book%20of%20Mystery_1702650207.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Egypt%27s%20Book%20of%20Mystery_1702647886.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.70358439,
        "gameCode": 73
    },
    {
        "id": "60531c5534d88c344ce9ac9c",
        "provider": "PGS",
        "gameName": "Emperor's Favour",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Emperor%27s%20Favour_1702650223.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Emperor%27s%20Favour_1702647927.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.33829582,
        "gameCode": 44
    },
    {
        "id": "60531c5534d88c344ce9aca5",
        "provider": "PGS",
        "gameName": "Flirting Scholar",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Flirting%20Scholar_1702650236.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Flirting%20Scholar_1702647953.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.61318545,
        "gameCode": 61
    },
    {
        "id": "60531c5534d88c344ce9ac84",
        "provider": "PGS",
        "gameName": "Fortune Gods",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Fortune%20Gods_1702650256.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Fortune%20Gods_1702647991.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.7572952,
        "gameCode": 3
    },
    {
        "id": "60531c5534d88c344ce9acab",
        "provider": "PGS",
        "gameName": "Fortune Mouse",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Fortune%20Mouse_1702650970.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Fortune%20Mouse_1702648019.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.22656197,
        "gameCode": 68
    },
    {
        "id": "60531c5534d88c344ce9ac87",
        "provider": "PGS",
        "gameName": "Tree of Fortune",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Tree%20of%20Fortune_1702652037.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Tree%20of%20Fortune_1702649316.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 92.12399334,
        "gameCode": 26
    },
    {
        "id": "60531c5534d88c344ce9acc6",
        "provider": "PGS",
        "gameName": "Galactic Gems",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Galactic%20Gems_1702651000.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Galactic%20Gems_1702648084.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.81558556,
        "gameCode": 86
    },
    {
        "id": "60531c5534d88c344ce9ac9d",
        "provider": "PGS",
        "gameName": "Ganesha Gold",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Ganesha%20Gold_1702651014.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Ganesha%20Gold_1702648109.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.24640778,
        "gameCode": 42
    },
    {
        "id": "60531c5534d88c344ce9acc7",
        "provider": "PGS",
        "gameName": "Guardians of Ice and Fire",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Guardians%20of%20Ice%20and%20Fire_1702651079.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Guardians%20of%20Ice%20and%20Fire_1702648291.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.51869837,
        "gameCode": 91
    },
    {
        "id": "60531c5534d88c344ce9ac8d",
        "provider": "PGS",
        "gameName": "Gem Saviour",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Gem%20Saviour_1702651033.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Gem%20Saviour_1702648195.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 92.0039922,
        "gameCode": 2
    },
    {
        "id": "60531c5534d88c344ce9acad",
        "provider": "PGS",
        "gameName": "Gem Saviour Conquest",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Gem%20Saviour%20Conquest_1702651039.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Gem%20Saviour%20Conquest_1702648241.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.56356858,
        "gameCode": 62
    },
    {
        "id": "60531c5534d88c344ce9ac99",
        "provider": "PGS",
        "gameName": "Gem Saviour Sword",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Gem%20Saviour%20Sword_1702651048.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Gem%20Saviour%20Sword_1702648258.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.04285348,
        "gameCode": 38
    },
    {
        "id": "60531c5534d88c344ce9acbc",
        "provider": "PGS",
        "gameName": "Genie's 3 Wishes",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Genie%27s%203%20Wishes_1702651065.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Genie%27s%203%20Wishes_1702648272.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.26063076,
        "gameCode": 85
    },
    {
        "id": "60531c5534d88c344ce9ac96",
        "provider": "PGS",
        "gameName": "Hip Hop Panda",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Hip%20Hop%20Panda_1702651096.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Hip%20Hop%20Panda_1702648340.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.27264817,
        "gameCode": 33
    },
    {
        "id": "60531c5534d88c344ce9ac90",
        "provider": "PGS",
        "gameName": "Hood vs Wolf",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Hood%20vs%20Wolf_1702651108.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Hood%20vs%20Wolf_1702648369.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 93.6384607,
        "gameCode": 18
    },
    {
        "id": "60531c5534d88c344ce9ac91",
        "provider": "PGS",
        "gameName": "Hot Pot",
        "gameCategory": "SLOT",
        "gameType": [
            "JACKPOTS",
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Hotpot_1702651122.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Hotpot_1702648394.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 93.38261449,
        "gameCode": 28
    },
    {
        "id": "60531c5534d88c344ce9acc5",
        "provider": "PGS",
        "gameName": "Jack Frost's Winter",
        "gameCategory": "SLOT",
        "gameType": [
            "BUY_FREE_SPINS",
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Jack%20Frost%27s%20Winter_1702651143.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Jack%20Frost%27s%20Winter_1702648438.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.31917828,
        "gameCode": 97
    },
    {
        "id": "60531c5534d88c344ce9acc3",
        "provider": "PGS",
        "gameName": "Jewels of Prosperity",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Jewels%20of%20Prosperity_1702651152.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Jewels%20of%20Prosperity_1702648447.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.78148375,
        "gameCode": 88
    },
    {
        "id": "60531c5534d88c344ce9aca2",
        "provider": "PGS",
        "gameName": "Journey to the Wealth",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Journey%20to%20the%20Wealth_1702651161.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Journey%20to%20the%20Wealth_1702648468.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.38685883,
        "gameCode": 50
    },
    {
        "id": "60531c5534d88c344ce9ac9f",
        "provider": "PGS",
        "gameName": "Jungle Delight",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Jungle%20Delight_1702651169.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Jungle%20Delight_1702648620.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 98.77198349,
        "gameCode": 40
    },
    {
        "id": "60531c5534d88c344ce9ac94",
        "provider": "PGS",
        "gameName": "Legend of Hou Yi",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Legend%20of%20Hou%20Yi_1702651186.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Legend%20of%20Hou%20Yi_1702648648.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.61406953,
        "gameCode": 34
    },
    {
        "id": "60531c5534d88c344ce9aca4",
        "provider": "PGS",
        "gameName": "Leprechaun Riches",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Leprechaun%20Riches_1702651214.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Leprechaun%20Riches_1702648689.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.88535826,
        "gameCode": 60
    },
    {
        "id": "60531c5534d88c344ce9ac88",
        "provider": "PGS",
        "gameName": "Medusa ",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Medusa_1702651282.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Medusa_1702648805.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.26519849,
        "gameCode": 7
    },
    {
        "id": "60531c5534d88c344ce9ac86",
        "provider": "PGS",
        "gameName": "Medusa II",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Medusa%20II_1702651288.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Medusa%20II_1702648813.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.20039017,
        "gameCode": 6
    },
    {
        "id": "60531c5534d88c344ce9ac93",
        "provider": "PGS",
        "gameName": "Mr. Hallow-Win",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Mr.%20Hallow-Win_1702651312.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Mr.%20Hallow-Win_1702648859.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.72444749,
        "gameCode": 35
    },
    {
        "id": "60531c5534d88c344ce9aca7",
        "provider": "PGS",
        "gameName": "Muay Thai Champion",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Muay%20Thai%20Champion_1702651317.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Muay%20Thai%20Champion_1702648866.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.533415,
        "gameCode": 64
    },
    {
        "id": "60531c5534d88c344ce9aca6",
        "provider": "PGS",
        "gameName": "Ninja vs Samurai",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Ninja%20vs%20Samurai_1702651336.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Ninja%20vs%20Samurai_1702648913.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.31178691,
        "gameCode": 59
    },
    {
        "id": "60531c5534d88c344ce9acc8",
        "provider": "PGS",
        "gameName": "Opera Dynasty",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Opera%20Dynasty_1702651341.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Opera%20Dynasty_1702648951.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.81417698,
        "gameCode": 93
    },
    {
        "id": "60531c5534d88c344ce9acb6",
        "provider": "PGS",
        "gameName": "Phoenix Rises",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Phoenix%20Rises_1702652085.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Phoenix%20Rises_1702648975.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.7493185,
        "gameCode": 82
    },
    {
        "id": "60531c5534d88c344ce9ac9a",
        "provider": "PGS",
        "gameName": "Piggy Gold",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Piggy%20Gold_1702651357.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Piggy%20Gold_1702648980.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.42097164,
        "gameCode": 39
    },
    {
        "id": "60531c5534d88c344ce9ac8b",
        "provider": "PGS",
        "gameName": "Plushie Frenzy",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Plushie%20Frenzy_1702652091.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Plushie%20Frenzy_1702648999.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 93.46181368,
        "gameCode": 25
    },
    {
        "id": "60531c5534d88c344ce9ac95",
        "provider": "PGS",
        "gameName": "Prosperity Lion",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Prosperity%20Lion_1702651375.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Prosperity%20Lion_1702649025.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.70151493,
        "gameCode": 36
    },
    {
        "id": "60531c5534d88c344ce9acc2",
        "provider": "PGS",
        "gameName": "Queen of Bounty ",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Queen%20of%20Bounty_1702651379.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Queen%20of%20Bounty_1702649030.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.460337,
        "gameCode": 84
    },
    {
        "id": "60531c5534d88c344ce9acac",
        "provider": "PGS",
        "gameName": "Reel Love",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Reel%20Love_1702651400.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Reel%20Love_1702649083.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 99.60421244,
        "gameCode": 20
    },
    {
        "id": "60531c5534d88c344ce9ac97",
        "provider": "PGS",
        "gameName": "Santa's Gift Rush",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Santa%27s%20Gift%20Rush_1702651424.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Santa%27s%20Gift%20Rush_1702649115.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.95758585,
        "gameCode": 37
    },
    {
        "id": "60531c5534d88c344ce9acc0",
        "provider": "PGS",
        "gameName": "Secret of Cleopatra",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Secret%20of%20Cleopatra_1702652136.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Secret%20of%20Cleopatra_1702649143.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 94.77253987,
        "gameCode": 90
    },
    {
        "id": "60531c5534d88c344ce9acae",
        "provider": "PGS",
        "gameName": "Shaolin Soccer",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Shaolin%20Soccer_1702652145.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Shaolin%20Soccer_1702649151.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.59373927,
        "gameCode": 67
    },
    {
        "id": "60531c5534d88c344ce9acbf",
        "provider": "PGS",
        "gameName": "Thai River Wonders",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Thai%20River%20Wonders_1702651480.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Thai%20River%20Wonders_1702649225.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.46672307,
        "gameCode": 92
    },
    {
        "id": "60531c5534d88c344ce9aca1",
        "provider": "PGS",
        "gameName": "The Great Icescape",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_The%20Great%20Icescape_1702651497.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_The%20Great%20Icescape_1702649252.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.71375398,
        "gameCode": 53
    },
    {
        "id": "60531c5534d88c344ce9acc1",
        "provider": "PGS",
        "gameName": "Vampire's Charm",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Vampire%27s%20Charm_1702651526.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Vampire%27s%20Charm_1702649321.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.4707262,
        "gameCode": 58
    },
    {
        "id": "60531c5534d88c344ce9acba",
        "provider": "PGS",
        "gameName": "Wild Fireworks",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Wild%20Fireworks_1702652176.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Wild%20Fireworks_1702649394.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.14524159,
        "gameCode": 83
    },
    {
        "id": "60531c5534d88c344ce9ac85",
        "provider": "PGS",
        "gameName": "Win Win Won",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Win%20Win%20Won_1702652168.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Win%20Win%20Won_1702649433.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 94.18885741,
        "gameCode": 24
    },
    {
        "id": "61af2980e5d289e90f512721",
        "provider": "PGS",
        "gameName": "Heist Stakes",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Heist%20Stakes_1702651090.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Heist%20Stakes_1702648380.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 99.92392239,
        "gameCode": 105
    },
    {
        "id": "61af2980e5d289e90f512722",
        "provider": "PGS",
        "gameName": "Rise of Apollo",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Rise%20of%20Apollo_1702651404.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Rise%20of%20Apollo_1702649089.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.84373617,
        "gameCode": 101
    },
    {
        "id": "61af2980e5d289e90f512724",
        "provider": "PGS",
        "gameName": "Jurassic Kingdom",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Jurassic%20Kingdom_1702651175.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Jurassic%20Kingdom_1702648626.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.71890141,
        "gameCode": 110
    },
    {
        "id": "61af2980e5d289e90f512725",
        "provider": "PGS",
        "gameName": "Mermaid Riches",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Mermaid%20Riches_1702651294.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Mermaid%20Riches_1702648835.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.62929073,
        "gameCode": 102
    },
    {
        "id": "61af2980e5d289e90f512727",
        "provider": "PGS",
        "gameName": "Raider Jane's Crypt of Fortune",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Raider%20Jane%27s%20Crypt%20of%20Fortune_1702651390.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Raider%20Jane%27s%20Crypt%20of%20Fortune_1702649051.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.05730392,
        "gameCode": 113
    },
    {
        "id": "61af2980e5d289e90f512728",
        "provider": "PGS",
        "gameName": "Supermarket Spree",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Supermarket%20Spree_1702650023.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Supermarket%20Spree_1702647214.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.73430309,
        "gameCode": 115
    },
    {
        "id": "61af2980e5d289e90f512729",
        "provider": "PGS",
        "gameName": "Buffalo Win",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Buffalo%20Win_1702650079.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Buffalo%20Win_1702647503.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.5135457,
        "gameCode": 108
    },
    {
        "id": "61af2980e5d289e90f51272a",
        "provider": "PGS",
        "gameName": "Legendary Monkey King",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Legendary%20Monkey%20King_1702651208.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Legendary%20Monkey%20King_1702648682.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.15589558,
        "gameCode": 107
    },
    {
        "id": "61af2980e5d289e90f51272b",
        "provider": "PGS",
        "gameName": "Spirited Wonders",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Spirited%20Wonders_1702652202.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Spirited%20Wonders_1702649204.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 97.04630221,
        "gameCode": 119
    },
    {
        "id": "61af2980e5d289e90f51272d",
        "provider": "PGS",
        "gameName": "Emoji Riches",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Emoji%20Riches_1702650215.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Emoji%20Riches_1702647900.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 93.13728307,
        "gameCode": 114
    },
    {
        "id": "60531c5534d88c344ce9ac9b",
        "provider": "PGS",
        "gameName": "Symbols of Egypt",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Symbols%20of%20Egypt_1702651474.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Symbols%20of%20Egypt_1702649211.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 90.21232704,
        "gameCode": 41
    },
    {
        "id": "609e78ea4a67b994b50d2a62",
        "provider": "PGS",
        "gameName": "Bali Vacation",
        "gameCategory": "SLOT",
        "gameType": [
            "BUY_FREE_SPINS",
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Bali%20Vacation_1702650060.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Bali%20Vacation_1702647392.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 98.82597099,
        "gameCode": 94
    },
    {
        "id": "609e78ea4a67b994b50d2a65",
        "provider": "PGS",
        "gameName": "Candy Bonanza",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Candy%20Bonanza_1702650100.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Candy%20Bonanza_1702647564.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.55988549,
        "gameCode": 100
    },
    {
        "id": "609e78ea4a67b994b50d2a63",
        "provider": "PGS",
        "gameName": "Crypto Gold",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS",
            "MEGAWAYS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Crypto%20Gold_1702650145.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Crypto%20Gold_1702647704.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.30584436,
        "gameCode": 103
    },
    {
        "id": "609e78ea4a67b994b50d2a64",
        "provider": "PGS",
        "gameName": "Majestic Treasures",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Majestic%20Treasures_1702651266.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Majestic%20Treasures_1702648780.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.40998142,
        "gameCode": 95
    },
    {
        "id": "6218ca10cc0fd496ed8ae93f",
        "provider": "PGS",
        "gameName": "Fortune Tiger",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Fortune%20Tiger_1702650988.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Fortune%20Tiger_1702648070.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.77316898,
        "gameCode": 126
    },
    {
        "id": "6218ca73cc0fd496ed8ae943",
        "provider": "PGS",
        "gameName": "Destiny of Sun & Moon",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Destiny%20of%20Sun%20&%20Moon_1702650151.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Destiny%20of%20Sun%20&%20Moon_1702647733.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.40833555,
        "gameCode": 121
    },
    {
        "id": "6218ca2dcc0fd496ed8ae940",
        "provider": "PGS",
        "gameName": "Mask Carnival",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Mask%20Carnival_1702651277.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Mask%20Carnival_1702648784.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 94.01649927,
        "gameCode": 118
    },
    {
        "id": "6218ca5fcc0fd496ed8ae942",
        "provider": "PGS",
        "gameName": "Garuda Gems",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Garuda%20Gems_1702651026.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Garuda%20Gems_1702648122.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 95.98230036,
        "gameCode": 122
    },
    {
        "id": "6218ca4ccc0fd496ed8ae941",
        "provider": "PGS",
        "gameName": "Oriental Prosperity",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Oriental%20Prosperity_1702651347.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Oriental%20Prosperity_1702648957.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.16723529,
        "gameCode": 112
    },
    {
        "id": "6218c884cc0fd496ed8ae93e",
        "provider": "PGS",
        "gameName": "Cocktail Nights",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Cocktail%20Nights_1702650133.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Cocktail%20Nights_1702647678.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.43057227,
        "gameCode": 117
    },
    {
        "id": "624ebf1d711ab46abc53530a",
        "provider": "PGS",
        "gameName": "Butterfly Blossom",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Butterfly%20Blossom_1702650087.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Butterfly%20Blossom_1702647525.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.3251102,
        "gameCode": 125
    },
    {
        "id": "624ebfccac4e82ccdb2dd3c0",
        "provider": "PGS",
        "gameName": "Rooster Rumble",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Rooster%20Rumble_1702651410.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Rooster%20Rumble_1702649110.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 98.37499501,
        "gameCode": 123
    },
    {
        "id": "625d462e711ab46abc535314",
        "provider": "PGS",
        "gameName": "The Queen’s Banquet",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_The%20Queen%E2%80%99s%20Banquet_1702652223.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_The%20Queen%E2%80%99s%20Banquet_1702649257.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.67438814,
        "gameCode": 120
    },
    {
        "id": "62791f90e9ccc220061c4ad0",
        "provider": "PGS",
        "gameName": "Battleground Royale",
        "gameCategory": "SLOT",
        "gameType": [
            "SLOT",
            "BUY_FREE_SPINS"
        ],
        "image": {
            "vertical": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/L/PGS_Battleground%20Royale_1702650066.webp",
            "horizontal": "https://cdn.happywinapi.com/img/sportsbook/game/PGS/S/PGS_Battleground%20Royale_1702647420.webp",
            "banner": ""
        },
        "status": "ACTIVE",
        "rtp": 96.86387946,
        "gameCode": 124
    }
]