import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import { IPJJAZZGame } from '../../entities/PJJAZZType'
import { CategoryGamePJJAZZ } from '../../utils/categoryGame'
import { toast } from 'react-toastify'
import api from '../../helpers/api'
import { makePath } from '../../helpers/path'
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import LoadingLayout from '../Layout/LoadingLayout';
import SeoContent from '../Layout/SeoContent';
import Footer from '../Layout/Footer';
import Popup from '../Popup';
import { Empty } from 'antd';
import MainSlider from '../Wallet/MainSlider';
import GameList from './PJJAZZ/GameList';
import CheckInModal from '../CheckIn/CheckInModal';

const PJJAZZGame = () => {
    const location = useLocation();
    const history = useHistory();

    const [config, setConfig] = useState<IPJJAZZGame[] | any>([])
    const [provider, setProvider] = useState('')
    const [pathName, setPathName] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(true)
    const [loadingGame, setLoadingGame] = useState<boolean>(true)
    const [gameType, setGameType] = useState<string>('pgsoft')
    const [allProvider, setAllProvider] = useState<any>(undefined)

    const providerState = useSelector((state: ApplicationState) => state.provider)
    const providerHomeState = useSelector((state: ApplicationState) => state.gamesHome)
    const prefixState = useSelector((state: ApplicationState) => state.prefix)

    useEffect(() => {
        if (providerState?.info !== undefined && location.pathname === '/launch-game') {
            setConfig(providerState?.info)
            setAllProvider(providerState?.info)
            if (Object.values(providerState?.info).length === 1) {
                setGameType(Object.keys(providerState?.info)[0]);
            }

            setLoadingGame(true)
        }

        if (providerHomeState?.info !== undefined && location.pathname === '/home') {
            setConfig(providerHomeState?.info)
            setAllProvider(providerHomeState?.info)
            if (Object.values(providerHomeState?.info).length === 1) {
                setGameType(Object.keys(providerHomeState?.info)[0]);
            }

            setLoadingGame(true)
        }

        setPathName(location.pathname === '/launch-game' ? 'launch-game' : 'home')

        setLoading(false)
    }, [providerState?.info, providerHomeState?.info, location]) // eslint-disable-line
    // console.log(config);


    const handleGameType = (game: string) => {
        // const params = new URLSearchParams(location.search);

        window.scrollTo({ top: 0, behavior: 'smooth' });
        setGameType(game)
        // params.set('gameType', game);
        // history.push({ search: params.toString() });
    }

    const TabGameType: any = loadingGame && CategoryGamePJJAZZ.filter((item) => {
        if (Object(allProvider)[(item.key).toLowerCase()] === undefined) {
            return false;
        }
        return true;
    }).map((x) => {
        return (
            <div id={x.key} className={`ant-tabs-tab ${x.key === gameType ? 'ant-tabs-tab-active' : ''}`} key={`menu-tab-${x.key}`} onClick={() => { handleGameType(x.key) }}>
                <div className="ant-tabs-tab-btn">
                    <div className="menu-tab">
                        <div className="icon-pjjazz" >
                            <img src={x.image} alt="nav" style={{ width: x.key === "pgsoft" ? '40px' : undefined }} />
                        </div>
                        {/* <span>
                            <strong>{x.title}</strong>
                        </span> */}
                    </div>
                </div>
            </div>
        )
    })

    return (
        <div className="ambking-container">
            <MainSlider prefixState={prefixState} />

            <div className="ant-tabs ant-tabs-top ambking-new-tab" style={{ marginTop: 12 }}>
                <div className="ant-tabs-nav">
                    <div className="ant-tabs-nav-list">
                        {TabGameType}
                    </div>
                </div>
                <div className="ambking-tab">
                    {loading ? (
                        <LoadingLayout prefix={prefixState} />
                    ) : (
                        config[gameType]?.length > 0 ? (
                            <div key={`games-slot`}>
                                <GameList
                                    games={config[gameType]}
                                    gameType={gameType}
                                    loadingProvider={loading}
                                    setLoading={setLoading}
                                    pathName={pathName}
                                />
                            </div>
                        ) : (
                            <div style={{ display: 'flex', width: '100%', marginTop: '12px' }}>
                                <Empty
                                    description='ไม่พบเกมส์'
                                    image="/icons/new/empty-box.svg"
                                    imageStyle={{ height: 88 }}
                                    style={{ flex: 'auto', marginTop: 24 }}
                                />
                            </div>
                        )
                    )}
                </div>
            </div>

            {location.pathname === '/home' && <SeoContent />}

            <Footer prefixState={prefixState} />

            {/* Popup */}
            <Popup />

            {/* CheckIn */}
            {prefixState.info?.checkin.enable && <CheckInModal />}
        </div>
    )
}

export default PJJAZZGame
