import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import WalletDepositItem from '../components/Deposit/WalletDepositItem'
import { ApplicationState } from '../store'
import { Modal, Typography } from 'antd'
import { useAppContext } from '../contexts/AppContext'

const { Text } = Typography

interface Props {
	isOpen: boolean
	onClose: () => void
}

const WalletDeposit: FC<Props> = ({ isOpen, onClose }) => {
	const depositToList = useSelector((state: ApplicationState) => state.deposit.depositToList)
	const userState = useSelector((state: ApplicationState) => state.user)

	const { isModalSocket, setIsModalSocket } = useAppContext();

	useEffect(() => {
		if (isModalSocket === false) {
			onClose()
			setIsModalSocket(true)
		}
	}, [isModalSocket]) //eslint-disable-line

	const depositToTruewallets = depositToList.filter((bank) => bank.bank === 'truewallet')
	if (depositToTruewallets.length <= 0) {
		return <Redirect to="/deposit" />
	}

	return (
		<Modal
			className="ant-modal-deposit"
			// open={isModalSocket && isOpen ? true : false}
			open={isOpen}
			title="โอนผ่านทรูวอลเล็ท"
			centered
			onCancel={onClose}
			footer={null}
		>
			<div className="notice-card warning">
				<div className="notice-card-icon">
					<img src="/icons/new/notice.svg" alt="notice icon" />
				</div>
				<Text className="notice-card-text">
					ระบบจะเติมเงินอัตโนมัติเข้ายูสเซอร์ของท่าน <Text type="warning"><strong>ภายใน 3 วินาที</strong></Text>
				</Text>
			</div>
			<div className="notice-card danger">
				<div className="notice-card-icon">
					<img src="/icons/new/danger.svg" alt="notice icon" />
				</div>
				<Text className="notice-card-text">
					สามารถโอนเงินเข้าทรูมันนี่วอลเล็ทโดยใช้ <Text type="danger"><strong>ชื่อและเบอร์โทรศัพท์</strong></Text> ที่สมัครโอนเข้ามาเท่านั้น
				</Text>
			</div>

			<div className="bank-deposit-card bank-form" style={{ marginBottom: 16 }}>
				<div
					className="bank-card-logo"
					style={{
						backgroundColor: '#fff',
						boxShadow: '0 8px 12px -4px #ffffff'
					}}
				>
					<img
						width={56}
						src="/bank-logo/truewallet.svg"
						alt="ทรูมันนี่วอลเล็ท"
					/>
				</div>
				<div className="bank-card-info">
					<div><Text type="secondary"><small>ทรูมันนี่วอลเล็ท</small></Text></div>
					<Text className="bank-deposite-account">
						{userState.info?.bankCode === "TRUEWALLET" ? userState.info?.bankNumber : userState.info?.phone}
					</Text>
					<div><Text type="secondary"><small>{userState.info?.firstName} {userState.info?.lastName}</small></Text></div>
				</div>
			</div>
			<div style={{ margin: '16px 0', textAlign: 'center' }}>
				<i className="fa-solid fa-arrow-down"></i>
			</div>
			{depositToTruewallets.map((bank) => (
				<WalletDepositItem bank={bank} key={bank.accountNumber} />
			))}
		</Modal>
	)
}

export default WalletDeposit
