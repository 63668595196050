import React, { FC, MouseEvent } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Member } from '../../@types/member'
import { makePath } from '../../helpers/path'
import { useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import AuthService from '../../services/auth'
import { setUser } from '../../store/user/user.actions'
import { Prefix } from '../../@types/prefix'

interface BottomBarProps {
	prefix?: Prefix
	member: Member | undefined
}
const BottomBar: FC<BottomBarProps> = ({ member, prefix }) => {
	const history = useHistory()
	const loc = useLocation()
	const dispatch = useDispatch()

	const launchGame = (e: MouseEvent<any>) => {
		e.preventDefault()

		if (!member?.game.meta) {
			toast.error('กรุณาฝากเงินครั้งแรกเพื่อเข้าเล่นเกม')
			return
		}
		// history.push(makePath('/launch-game', [`t=${Date.now() + 5000}`]))
		history.push(makePath('/launch-game'))
	}

	const refetchCredit = async () => {
		try {
			const res = await AuthService.getMemberInfo(true)
			dispatch(setUser(res))
		} catch ({ message }: any) {
			toast.error(message as string)
		}
	}

	return (
		// <div className="bottombar-mobile">
		// 	<ul className="nav-menu">
		// 		<li className="nav-item">
		// 			<Link
		// 				to={makePath('/wallet')}
		// 				onClick={() => { refetchCredit() }}
		// 				className={`nav-link ${loc.pathname === '/wallet' && 'active'}`}
		// 			>
		// 				<img src={`/icons/new/wallet.svg`} alt="icon" />
		// 				<span>กระเป๋า</span>
		// 			</Link>
		// 		</li>
		// 		<li className="nav-item">
		// 			<Link
		// 				to={makePath('/deposit')}
		// 				className={`nav-link ${(loc.pathname === '/deposit' || loc.pathname === '/deposit/bank') && 'active'}`}
		// 			>
		// 				<img src={`/icons/new/deposit.svg`} alt="icon" />
		// 				<span>เติมเงิน</span>
		// 			</Link>
		// 		</li>
		// 		<li className="nav-item middle-item">
		// 			<a href="#game" className={`nav-link ${loc.pathname === '/launch-game' && 'active'}`} onClick={launchGame}>
		// 				<div className="icon-button-container">
		// 					<div className="icon-button">
		// 						<div className="icon-container" />
		// 					</div>
		// 					<img className="icon-center" src={`/icons/new/play_new.png`} alt="icon" />
		// 				</div>
		// 				<span>เข้าเล่นเกม</span>
		// 			</a>
		// 		</li>
		// 		<li className="nav-item">
		// 			<Link
		// 				to={makePath('/promotion')}
		// 				className={`nav-link ${loc.pathname === '/promotion' && 'active'}`}
		// 			>
		// 				<img src={`/icons/new/promotion.svg`} alt="icon" />
		// 				<span>โปรโมชั่น</span>
		// 			</Link>
		// 		</li>
		// 		<li className="nav-item">
		// 			<Link
		// 				to={makePath('/profile')}
		// 				className={`nav-link ${loc.pathname === '/profile' && 'active'}`}
		// 			>
		// 				<img src={`/icons/new/account.svg`} alt="icon" />
		// 				<span>โปรไฟล์</span>
		// 			</Link>
		// 		</li>
		// 	</ul>
		// </div>
		<div className="bottombar-mobile">
			<div className="bg-wrapper">
				<div className="bg-gradient-wrapper"></div>
				<svg width="112" height="64" viewBox="0 0 112 64" fill={prefix?.themeColor?.secondary} xmlns="http://www.w3.org/2000/svg">
					<g clipPath="url(#clip0_6129_102583)">
						<path d="M0 0C8.06271 0 15.5318 3.23866 19.6663 10.1606L21.366 13.0061C37.3247 39.7235 76.4234 38.6903 91.287 11.3484C95.408 3.76785 103.372 0 112 0V79H57.5H0V0Z" fill="var(--footer)"></path>
						<path d="M0 0C8.06271 0 15.5318 4.23866 19.6663 11.1606L21.366 14.0061C37.3247 40.7235 76.4234 39.6903 91.287 12.3484C95.408 4.76785 103.372 0 112 0V80H57.5H0V0Z" fill="url(#paint0_linear_6129_102583)"></path>
					</g>
					<defs><linearGradient id="paint0_linear_6129_102583" x1="56" y1="0" x2="56" y2="64" gradientUnits="userSpaceOnUse">
						<stop stopColor="var(--color-primary-end)"></stop>
						<stop offset="1" stopColor="var(--color-primary-start)"></stop>
					</linearGradient>
						<clipPath id="clip0_6129_102583">
							<rect width="112" height="64" fill="white"></rect>
						</clipPath>
					</defs>
				</svg>
				<div className="bg-gradient-wrapper"></div>
			</div>

			<div className="navigate-wrapper">
				<div className="navigate-container">
					<Link
						to={makePath('/wallet')}
						onClick={() => { refetchCredit() }}
						className={`navigate-item ${loc.pathname === '/wallet' ? 'active' : ''}`}
					>
						<i className="fa-solid fa-wallet"></i>
						<span>กระเป๋า</span>
					</Link>
					<Link
						to={makePath('/deposit')}
						className={`navigate-item ${(loc.pathname === '/deposit' || loc.pathname === '/deposit/bank') && 'active'}`}
					>
						<i className="fa-solid fa-money-bill-transfer"></i>
						<span>ฝากเงิน</span>
					</Link>
					<Link to="#game" className="navigate-item" onClick={launchGame}>
						<div className="navigate-main-icon">
							{prefix?.prefix === "PGAWIN" ?
								<img src="https://i.ibb.co/8d4hx3T/icon.png" alt="icon" style={{ width: 56, animation: 'dance 1s infinite' }} /> :
								prefix?.playgameImage ?
									<img className="icon-center" src={prefix?.playgameImage} alt="icon" style={{ width: 56, animation: 'dance 1s infinite' }} /> :
									<i className="fa-solid fa-gamepad-modern"></i>
							}
						</div>
						<span>เล่นเกม</span>
					</Link>
					<Link
						to={makePath('/promotion')}
						className={`navigate-item ${loc.pathname === '/promotion' ? 'active' : ''}`}
					>
						<i className="fa-solid fa-gift"></i>
						<span>โปรโมชั่น</span>
					</Link>
					<Link
						to={makePath('/profile')}
						className={`navigate-item ${loc.pathname === '/profile' ? 'active' : ''}`}
					>
						<i className="fa-solid fa-user"></i>
						<span>โปรไฟล์</span>
					</Link>
				</div>
			</div>
		</div>
	)
}

export default BottomBar
