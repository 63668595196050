import React, { useEffect, useState } from 'react'
import { PatternFormat } from 'react-number-format';
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { makePath } from '../helpers/path'
import AuthService from '../services/auth'
import { setUser } from '../store/user/user.actions'
import { Button, Checkbox, Flex, Form, Input } from 'antd'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import ModalSupport from '../components/Home/ModalSupport';
import ForgotPassword from '../components/Login/ForgotPassword';
import { Space, Typography, Tabs } from 'antd';
import type { TabsProps } from 'antd';

const { Text } = Typography;

const Login = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const [form] = Form.useForm();
	const [isSubmitting, setSubmitting] = useState<boolean>(false)
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const prefix = prefixState.info
	const [openSupport, setOpenSupport] = useState<boolean>(false)
	const handleModalSupport = () => setOpenSupport(!openSupport)
	const [forgotPassword, setForgotPassword] = useState<boolean>(false)
	const handleForgotPassword = () => setForgotPassword(!forgotPassword)
	const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)
	const isRegister = !prefixState?.info?.functions.hasOwnProperty("offRegister") || !prefixState?.info?.functions['offRegister'];

	const handleSubmit = async () => {
		form.validateFields()
			.then(async (values: any) => {
				setSubmitting(true)
				if (values.remember) {
					const encryptedData = btoa(JSON.stringify({
						'username': values.phone,
						'password': values.password,
						'rememberMe': values.remember
					}));
					localStorage.setItem('rememberedUser', encryptedData);
				} else {
					localStorage.removeItem('rememberedUser');
				}
				delete values.remember
				try {
					const res = await AuthService.login({
						phone: values.phone.replace(/-/g, ''),
						password: values.password,
					})

					dispatch(setUser(res))
					if ((prefix?.game)?.toUpperCase() === "AMBKING" ||
						(prefix?.game === "betflix" && prefix?.functions.betflixOnListgame) ||
						(prefix?.game === "pgoon" && prefix?.functions.pgoonOnListgame) ||
						(prefix?.game === "pjjazz" && prefix?.functions.pgoonOnListgame)
					) {
						history.push(makePath('/launch-game'))
					} else {
						history.push(makePath('/wallet'))
					}
				} catch ({ message }: any) {
					toast.error(message as string)
					setSubmitting(false)
				} finally {
					setSubmitting(false)
				}
			}).catch();
	}

	const handleTabs = (key: string) => {
		if (key === "register") {
			history.push(makePath('/register'))
		}
	};

	const itemTabs: TabsProps['items'] = [
		{
			key: 'login',
			label: 'เข้าสู่ระบบ',
		},
		{
			key: 'register',
			label: 'สมัครสมาชิก',
		},
	];

	useEffect(() => {
		if (prefixState.info) {
			const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
				if (value === "") {
					return true;
				}
				return false;
			}) : true;
			setShowMenuSupport(isNullishSocialMedia)
		}
	}, [prefixState])

	useEffect(() => {
		if (form) {
			form.setFieldsValue({
				'phone': '',
				'password': '',
				'remember': false
			})
			// const rememberedUser = Cookies.get('rememberedUser');
			const rememberedUser = localStorage.getItem('rememberedUser');
			if (rememberedUser !== undefined && rememberedUser !== null) {
				const decodedString = atob(rememberedUser as string);
				if (decodedString) {
					const { username, password, rememberMe } = JSON.parse(decodedString);
					// setRememberMe(rememberMe);
					if (form) {
						form.setFieldsValue({
							'phone': username,
							'password': password,
							'remember': rememberMe
						})
					}
				}
			}
		}
	}, [form])

	return (
		<>
			<div className="form-card">
				<div className="form-card-body" style={{ paddingTop: isRegister ? 12 : 20, paddingBottom: 20 }}>
					{isRegister && (
						<Tabs defaultActiveKey="login" className="tab-auth" items={itemTabs} onTabClick={handleTabs} />
					)}
					<Form
						form={form}
						name="truewalletqrdialogForm"
						layout="vertical"
						style={{ marginTop: 12 }}
						hideRequiredMark
					>
						<Form.Item
							name="phone"
							rules={[{ required: true, message: 'กรุณากรอกเบอร์โทรศัพท์' }]}
							style={{ marginBottom: 12 }}
						>
							<PatternFormat
								customInput={Input}
								format="###-###-####"
								mask="_"
								type="tel"
								placeholder="เบอร์โทรศัพท์"
							/>
						</Form.Item>
						<Form.Item
							name="password"
							rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}
							style={{ marginBottom: 8 }}
						>
							<Input.Password
								placeholder="รหัสผ่าน" inputMode='text'
							// onChange={(e) => setPassword(e.target.value)}
							/>
						</Form.Item>
						<Flex justify="space-between" align="center" style={{ marginBottom: 8 }}>
							<Form.Item
								name="remember"
								valuePropName="checked"
								style={{ marginBottom: 0 }}
							>
								<Checkbox>จดจำฉัน</Checkbox>
							</Form.Item>
							<Button type="link" onClick={handleForgotPassword} style={{ paddingRight: 0 }}>
								<span style={{ color: '#fff', textDecoration: 'underline' }}>ลืมรหัสผ่าน?</span>
							</Button>
						</Flex>
						<Button
							type="primary"
							htmlType="submit"
							loading={isSubmitting}
							onClick={handleSubmit}
							style={{ marginBottom: 16 }}
							block
						>
							เข้าสู่ระบบ
						</Button>
						{prefix?.functions?.lineLogin &&
							<Link to={makePath('/login-line')}>
								<Button className="ant-btn-dark" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} block>
									<Space size={12}>
										<img src="/icons/new/line-logo.svg" alt="line logo" style={{ width: 22, paddingTop: 1 }} />
										<div>
											<span>เข้าสู่ระบบด้วย LINE</span>
										</div>
									</Space>
								</Button>
							</Link>
						}
					</Form>
				</div>
			</div>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 12 }}>
				{(prefixState?.info?.functions.hasOwnProperty("homePage") && prefixState?.info?.functions['homePage']) && (
					<Link to={makePath('/home')}>
						<div style={{ padding: '8px 0' }}>
							<Text><i className="fa-regular fa-arrow-left" style={{ fontSize: '18px', marginRight: 5 }}></i> กลับหน้าหลัก</Text>
						</div>
					</Link>
				)}
				{!showMenuSupport && (
					<Link to={"#"} onClick={handleModalSupport}>
						<div style={{ textAlign: 'center', padding: '8px 0 4px' }}>
							<Text><span>ฝ่ายบริการลูกค้า</span><i className="fa-solid fa-headset" style={{ fontSize: '18px', marginLeft: 8 }}></i></Text>
						</div>
					</Link>
				)}
				{prefix?.lineLink && showMenuSupport && (
					<Link to={{ pathname: prefix.lineLink }} target="_blank">
						<div style={{ textAlign: 'center', padding: '8px 0 4px' }}>
							<Text>ฝ่ายบริการลูกค้า</Text>
						</div>
					</Link>
				)}
			</div>


			<ModalSupport isOpen={openSupport} onClose={() => setOpenSupport(false)} />
			<ForgotPassword isOpen={forgotPassword} onClose={() => setForgotPassword(false)} />
		</>
	)
}

export default Login
