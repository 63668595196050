import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'antd'
import CheckInContent from '../components/CheckIn/CheckInContent';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import { makePath } from '../helpers/path';
import { useHistory } from 'react-router-dom'
import ModalTerms from '../components/Elements/ModalTerms';

const CheckIn = () => {
    const history = useHistory()

    const [openTerms, setOpenTerms] = useState<boolean>(false)
    const handleModalTerms = () => setOpenTerms(!openTerms)

    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    if (!prefixState.info?.checkin.enable) {
        history.push(makePath('/wallet'))
    }

    return (
        <>
            <Row justify="center" className="margin-form-card">
                <Col xs={24} md={16} xl={12}>
                    <div className="form-card">
                        <div className="form-card-body-checkin" >
                            <CheckInContent />
                            {prefixState.info?.termandcondition?.checkinDescription && (
                                <div className="terms-and-conditions-button" >
                                    <Button className="ant-btn-dark" onClick={handleModalTerms} style={{ height: 32 }}>
                                        <i className="fa-solid fa-circle-info" style={{ fontSize: 16 }}></i>
                                        <span style={{ fontSize: 16 }}>กติกา</span>
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>

            <ModalTerms isOpen={openTerms} onClose={() => setOpenTerms(false)} description={prefixState.info?.termandcondition?.checkinDescription || ""} />
        </>
    )
}

export default CheckIn