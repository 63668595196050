import React, { useEffect, useState } from 'react'
import { Checkbox, Modal } from 'antd'
import CheckInContent from './CheckInContent'
import { useAppContext } from '../../contexts/AppContext';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { useLocation } from 'react-router';

const CheckInModal = () => {

    const loc = useLocation()

    const getPopupLocal = localStorage.getItem('popup')
    const getPopupCheckinLocal = localStorage.getItem('popup-checkin')
    const [isCheckInModal, setIsCheckInModal] = useState<boolean>(getPopupCheckinLocal === 'close' || loc.pathname === '/home' ? false : true)

    const { isModalCheckin, setIsModalCheckin } = useAppContext();
    const [isDontShow, setIsDontShow] = useState<boolean>(false)

    const prefixState = useSelector((state: ApplicationState) => state.prefix)

    const closePopup = () => {
        setIsCheckInModal(false)

        if (isDontShow) {
            const currentTime = new Date();
            currentTime.setHours(currentTime.getHours() + 1);
            const expirationTime = currentTime.getTime();
            localStorage.setItem('popup-checkin', "close")
            localStorage.setItem('popup-checkinExpiration', expirationTime.toString());
        }
    }

    useEffect(() => {
        if (!prefixState.info?.popups.enable || !prefixState.info?.popups.items || getPopupLocal === 'close') {
            setIsModalCheckin(true)
        }

        const expirationTime = localStorage.getItem('popup-checkinExpiration');
        if (expirationTime && (new Date().getTime() > Number(expirationTime))) {
            localStorage.removeItem('popup-checkin');
            localStorage.removeItem('popup-checkinExpiration');
        }
    }, []) // eslint-disable-line

    return (
        <Modal
            className="ant-popup ant-popup-checkin"
            open={isModalCheckin && isCheckInModal}
            title=""
            centered
            onCancel={closePopup}
            footer={null}
        >
            <div className="form-card">
                <div className="form-card-body">
                    <CheckInContent modal={true} />
                </div>
            </div>
            <div className="ant-pop-checkbox">
                <Checkbox onChange={() => setIsDontShow(!isDontShow)}>ไม่ต้องแสดงอีก</Checkbox>
            </div>
        </Modal>
    )
};

export default CheckInModal