import { LineRequest, Member } from '../@types/member'
import api from '../helpers/api'

interface LoginData {
	phone: string
	password: string
}
interface LoginResponse {
	token: string
}
export interface MemberResponse {
	member: Member
	credit: number
	creditOutStanding: number
	currentTurnover: number
	cashback: {
		enable: boolean
		available: boolean
		total: number
	}
	affiliate: {
		code: string
		link: string
	}
	myPromotion: {
		key: string,
		name: string,
		description: string,
		image: string
		delete: boolean
	}
	vizpayWithdrawSetting?: {
		displayFeeMemberStatus?: boolean
	}
	turnover: number
	showTurnover: boolean
	isPartner: boolean
	paymentMethods: {
		bank: boolean
		peer2pay: boolean
		truewallet: boolean
	}
}

class AuthService {
	private token: string = ''
	private member?: MemberResponse

	constructor() {
		const token = localStorage.getItem('x-auth')
		if (token) {
			this.setToken(token)
		}
	}

	setToken(token: string) {
		this.token = token

		localStorage.setItem('x-auth', token)
		api.defaults.headers['Authorization'] = `Bearer ${token}`
	}
	revokeToken() {
		this.token = ''

		localStorage.removeItem('x-auth')
		localStorage.removeItem('popup');
		localStorage.removeItem('popupExpiration');
		localStorage.removeItem('popup-checkin');
		localStorage.removeItem('popup-checkinExpiration');
		localStorage.removeItem('qr-pay');
		delete api.defaults.headers['Authorization']
	}

	async getMemberInfo(refetch: boolean = false): Promise<MemberResponse> {
		if (!this.token) {
			throw new Error('INVALID AUTH STATE')
		}
		if (this.member && !refetch) {
			return this.member
		}

		try {
			const res = await api.get<void, MemberResponse>('/member')

			this.member = res
			return this.member
		} catch (e) {
			throw e
		}
	}

	async login(data: LoginData): Promise<MemberResponse> {
		try {
			const res = await api.post<void, LoginResponse>('/login', data)

			this.setToken(res.token)
			return this.getMemberInfo(true)
		} catch (e) {
			throw e
		}
	}

	async loginLine(data: LineRequest): Promise<MemberResponse> {
		try {
			const res = await api.post<void, LoginResponse>('/login/line', data)

			this.setToken(res.token)
			return this.getMemberInfo(true)
		} catch (e) {
			throw e
		}
	}

	async loginToken(data: string): Promise<MemberResponse> {
		try {
			this.setToken(data)
			return this.getMemberInfo(true)
		} catch (e) {
			throw e
		}
	}
}

export default new AuthService()
