import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import AuthContainer from './components/Container/AuthContainer'
import AppLayout from './components/Layout/AppLayout'
import { makePath } from './helpers/path'
import Affiliate from './views/Affiliate'
import Commission from './views/Commission'
import AMBPay from './views/AMBPay'
import BankDeposit from './views/BankDeposit'
import Deposit from './views/Deposit'
import Games from './views/Games'
import LaunchGame from './views/LaunchGame'
import Logout from './views/Logout'
import LuckyWheel from './views/LuckyWheel'
import Profile from './views/Profile'
import Account from './views/Account'
import Promotion from './views/Promotion'
import Support from './views/Support'
import Transactions from './views/Transactions'
import Wallet from './views/Wallet'
import WalletDeposit from './views/WalletDeposit'
import Withdraw from './views/Withdraw'
import UploadSlip from './views/UploadSlip'
import Ranking from './views/Ranking'
import CheckIn from './views/CheckIn'
import ScrollToTop from './utils/ScrollToTop'
import useGaTracker from './utils/useGaTracker'
import Play from './views/Play'
import Partner from './views/Partner';

const App = () => {
	useGaTracker();
	const params = new URLSearchParams(window.location.search);
	const getrdrParam = params.get('rdr');

	if (getrdrParam && getrdrParam !== null) {
		window.location.href = getrdrParam;
		return null
	}

	return (
		<AuthContainer>
			<ScrollToTop />
			<AppLayout>
				<Switch>
					<Route path="/wallet" component={Wallet} />
					<Route exact path="/deposit" component={Deposit} />
					<Route exact path="/deposit/bank" component={BankDeposit} />
					<Route exact path="/deposit/wallet" component={WalletDeposit} />
					<Route exact path="/deposit/ambpay" component={AMBPay} />
					<Route exact path="/deposit/upload-slip" component={UploadSlip} />
					<Route path="/withdraw" component={Withdraw} />
					<Route path="/transactions" component={Transactions} />
					<Route path="/promotion" component={Promotion} />
					<Route path="/lucky-wheel" component={LuckyWheel} />
					<Route path="/affiliate" component={Affiliate} />
					<Route path="/commission" component={Commission} />
					<Route path="/profile" component={Profile} />
					<Route path="/account" component={Account} />
					<Route path="/launch-game" component={LaunchGame} />
					<Route path="/slot/:providerID" component={Games} />
					<Route path="/support" component={Support} />
					<Route path="/ranking" component={Ranking} />
					<Route path="/check-in" component={CheckIn} />
					<Route path="/play" component={Play} />
					<Route path="/partner" component={Partner} />
					<Route path="/logout" component={Logout} />

					<Redirect to={makePath('/wallet')} />
				</Switch>
			</AppLayout>
		</AuthContainer>
	)
}

export default App
