import React, { FC, useState } from 'react'
import { Member } from '../../@types/member'
import { getBankColor, getBankName, Banks } from '../../helpers/bank'
import WithdrawForm from './WithdrawForm'
import { format } from '../../helpers/number'
import { Badge, Button, Col, Descriptions, Form, Input, Row, Statistic, Typography } from 'antd'
import { toast } from 'react-toastify'
import api from '../../helpers/api'
import { requestUser } from '../../store/user/user.actions'
import { useDispatch } from 'react-redux'

const { Text } = Typography

interface Peer2PayInfoWidgetProps {
	credit: number
	info: Member
	paymentGateway: string
}

const Peer2PayInfoWidget: FC<Peer2PayInfoWidgetProps> = ({ credit, info, paymentGateway }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch()
	const [isSubmitting, setSubmitting] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false);

	const handleSubmit = async () => {
		setSubmitting(true)
		form.validateFields()
			.then(async (values: any) => {
				try {
					const res: any = await api.post('/member/peer2pay/update', {
						peer2PayUID: values.peer2PayUID
					})
					dispatch(requestUser())
					toast.success(res.message as string)
					setSubmitting(false)
				} catch (error: any) {
					toast.error(error.message as string)
					setSubmitting(false)
				}
			}).catch((errorInfo) => {
				setSubmitting(false)
			});
	}

	const pasteButton = (e: any) => {
		e.preventDefault();

		try {
			setLoading(true)

			setTimeout(async () => {
				const text = await navigator.clipboard.readText();
				form.setFieldsValue({ 'peer2PayUID': text });
				setLoading(false)
			}, 500);
		} catch (error) {
			console.error('Failed to read clipboard data:', error);
			setLoading(false)
		}
	}

	return (
		<>
			<Text type="secondary" style={{ marginBottom: 6, display: 'block' }}>ถอนเงินเข้า Peer2Pay</Text>
			<div style={{ marginBottom: 16 }}>
				<Badge.Ribbon text="ฝากถอน 1 วินาที ฟรีค่าธรรมเนียม" className="ant-ribbon-secondary">
					<div className="bank-card">
						<div
							className="bank-card-logo"
							style={{ boxShadow: `0 8px 12px -4px #fff` }}>
							<img
								src={Banks.filter(bankName => bankName.key === "PEER2PAY")[0].image}
								alt={"PEER2PAY"}
							/>
						</div>
						<div className="bank-card-info" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
							<div><small>Peer2Pay</small></div>
							<div className="bank-deposite-account">UID : {info.peer2PayUID ? (info.peer2PayUID) : '-'}</div>
						</div>
					</div>
				</Badge.Ribbon>
			</div>
			{info.peer2PayUID ? (
				<>
					<Row gutter={16} style={{ marginBottom: 24 }}>
						<Col span={12}>
							<Statistic title="จำนวนเงินที่ถอนได้" value={format(credit)} prefix="฿" />
						</Col>
						<Col span={12}>
							<Statistic title="ยอดถอนขั้นต่ำ" value={format(info.minWithdraw)} prefix="฿" />
						</Col>
					</Row>
					<WithdrawForm initAmount={credit} paymentGateway={paymentGateway} />
					<div style={{ textAlign: 'center', marginTop: 8 }}>
						<img src="/p2p/p2pbottom.png" alt="p2p bottom" style={{ display: 'block', margin: '16px auto 0', width: '160px' }} />
					</div>
				</>
			) : (
				<>
					<Form
						form={form}
						name="peer2payForm"
						layout="vertical"
						requiredMark={false}
					>
						<label htmlFor="peer2PayUID" style={{ marginBottom: 8, display: 'block' }}>Peer2Pay UID</label>
						<div style={{ position: 'relative', overflow: 'hidden' }}>
							<Form.Item
								name="peer2PayUID"
								rules={[{ required: true, message: 'กรุณากรอก Peer2Pay UID' }]}
								noStyle
							>
								<Input inputMode='text' placeholder="กรอก Peer2Pay UID ของคุณ" style={{ paddingTop: '11.25px', paddingBottom: '11.25px' }} />
							</Form.Item>
							<Button type="link" className="paste-btn" onClick={pasteButton} onTouchEnd={pasteButton} loading={loading} disabled={loading} style={{ height: '100%' }}>วาง</Button>
						</div>
						<Button
							type="primary"
							htmlType="submit"
							loading={isSubmitting}
							disabled={isSubmitting}
							onClick={handleSubmit}
							style={{ margin: '16px 0' }}
							block>
							ยืนยัน
						</Button>
					</Form>
					<Descriptions title="จะได้รับ Peer2Pay UID อย่างไร" column={1} size={'small'} colon={false} className="p2p-withdraw-description" >
						<Descriptions.Item label="•">เข้าสู่ระบบที่เว็บไซต์หรือแอปฯ <Typography.Link href="https://peer2pay.com/" target="_blank" style={{ marginLeft: 5, color: "#fff" }} underline>Peer2Pay</Typography.Link></Descriptions.Item>
						<Descriptions.Item label="•">กดที่โปรไฟล์ของคุณ</Descriptions.Item>
						<Descriptions.Item label="•">คัดลอก UID ของคุณ</Descriptions.Item>
					</Descriptions>

					<div style={{ textAlign: 'center', marginTop: 8 }}>
						<img src="/p2p/p2pbottom.png" alt="p2p bottom" style={{ display: 'block', margin: '8px auto', width: '160px' }} />
						<Text style={{ textAlign: 'center', fontSize: 16 }}>หากคุณยังไม่มีบัญชี Peer2Pay <Typography.Link href="https://peer2pay.com/th/auth/register/" style={{ fontSize: 16, color: "#fff" }} target="_blank" underline >สมัครตอนนี้</Typography.Link></Text>
					</div>
				</>
			)}
		</>
	)
}

export default Peer2PayInfoWidget
