import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import { makePath } from '../../helpers/path'
import AuthService from '../../services/auth'
import { ApplicationState } from '../../store'
import { requestUserDone, requestUserFailed, setUser, } from '../../store/user/user.actions'
import LoadingLayout from '../Layout/LoadingLayout'
import { requestProvider, requestProviderFailed, requestProviderInPromotion } from '../../store/provider/provider.action'
import api from '../../helpers/api'
import { LineRequest } from '../../@types/member'
import { toast } from 'react-toastify'
import { requestCheckIn } from '../../store/checkIn/checkIn.action'

const AuthContainer: FC = ({ children }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()

	const userState = useSelector((state: ApplicationState) => state.user)
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const layout = prefixState.info?.functions?.homePage //true = home, false = login
	const gameType = prefixState.info?.game
	const requestAuthState = useCallback(async (game: any) => {
		try {
			const res = await AuthService.getMemberInfo()

			dispatch(requestUserDone(res))

			const params = new URLSearchParams(location.search);
			const filterQuery = params.get('filter');

			dispatch(requestCheckIn(prefixState.info?.checkin.enable))

			if (filterQuery !== 'on') {
				dispatch(requestProvider(game, prefixState?.info?.gameMetaOtherStatus || false))
			} else {
				dispatch(requestProviderInPromotion(game, true))
			}
		} catch {
			localStorage.removeItem('x-auth')
			dispatch(requestUserFailed())
			dispatch(requestProviderFailed())
		}
	}, [dispatch])

	const [redirect, setRedirect] = useState<boolean>(false)

	const syncLineAccount = async (code: string, state: string) => {
		try {
			const res = await api.post<void, LineRequest>('/member/sync/line', { // eslint-disable-line
				lineCode: code,
				lineState: state
			})
			// ?code=cP7thGm0qJDyxCFOA5z9&state=5510043351
			// window.location.href = makePath('/profile')
			history.push(makePath('/profile'))
			setRedirect(true)
		} catch ({ message }: any) {
			toast.error(message as string)
			history.push(makePath('/profile'))
			setRedirect(true)
		}
	}

	const LoginLineAccount = async (code: string, state: string, game: any) => {
		try {
			const res = await AuthService.loginLine({
				lineCode: code,
				lineState: state,
			})

			dispatch(setUser(res))
			if (gameType === "ambking") {
				history.push(makePath('/launch-game'))
			} else {
				history.push(makePath('/wallet'))
			}
			setRedirect(true)
		} catch ({ message }: any) {
			toast.error(message as string)
			history.push(makePath('/login'))
		}
	}

	const LoginTokenAccount = async (token: string) => {
		try {
			const res = await AuthService.loginToken(token)

			dispatch(setUser(res))
			if (gameType === "ambking") {
				history.push(makePath('/launch-game'))
			} else {
				history.push(makePath('/wallet'))
			}
			setRedirect(true)
		} catch ({ message }: any) {
			toast.error(message as string)
			history.push(makePath('/login'))
		}
	}

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const getCodeParam = params.get('code');
		const getStateParam = params.get('state');
		const getLoginTokenParam = params.get('authLoginToken');
		if (getCodeParam && getCodeParam !== null && getStateParam && getStateParam !== null) {
			const token = localStorage.getItem('x-auth')
			if (token) {
				syncLineAccount(getCodeParam, getStateParam)
			} else {
				LoginLineAccount(getCodeParam, getStateParam, prefixState.info?.game)
			}
		} else if (getLoginTokenParam && getLoginTokenParam !== null) {
			LoginTokenAccount(getLoginTokenParam)
		} else {
			requestAuthState(prefixState.info?.game)
		}
	}, [prefixState.info?.game, requestAuthState, redirect]) // eslint-disable-line

	if (!userState.isLoaded) {
		return (
			<LoadingLayout prefix={prefixState} />
		)
	}
	if (userState.isLoaded && !userState.info) {
		if (layout) {
			return <Redirect to={makePath('/home')} />
		} else {
			return <Redirect to={makePath('/login')} />
		}

	}
	return <>{children}</>
}

export default AuthContainer
