import React, { FC, MouseEvent, useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { IPJJAZZLoginResponse, iPJJAZZListGame } from '../../../entities/PJJAZZType'
import api from '../../../helpers/api'
import { isMobile } from '../../../helpers/utils'
import { Button, Empty, Input, Space, Typography, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom'
import { makePath } from '../../../helpers/path'

const { Title } = Typography

interface GameListProps {
	games: iPJJAZZListGame[] | any
	gameType: string
	pathName: string
	loadingProvider: boolean

	// setProvider: (name: string) => void
	// setLoadingProvider: (e: boolean) => void
	setLoading: (e: boolean) => void
}

const GameList: FC<GameListProps> = ({
	games, gameType, pathName, setLoading, loadingProvider
}) => {
	const location = useLocation();
	// const ambkProviderTabContainerRef: any = useRef();
	// const ambkProviderTabRef: any = useRef();
	const ambkTopbarRef: any = useRef();
	const history = useHistory()
	const [messageApi, contextHolder] = message.useMessage();

	const [configGames, setConfigGames] = useState<any>(undefined)
	const [filterText, setFilterText] = useState('')

	const triggerSlotLogin = (gameID: string, productCode: string) => async (e: MouseEvent<any>) => {
		e.preventDefault()
		setLoading(true)
		try {
			// ----- เวลา login เข้าเล่นเกม ambking ใช้เส้นนี้นะครับ
			const res = await api.post<void, IPJJAZZLoginResponse>('/pjjazz/login', {
				gameID: gameID,
				productCode: productCode,
				gameCode: gameID
			})
			history.push(makePath('play?game=' + btoa(res.url) + '&path=' + btoa(history.location.pathname + history.location.search)))
			setTimeout(() => {
				setLoading(false)
			}, 2000);
		} catch ({ message }: any) {
			toast.error(message as string)
			setLoading(false)
		}
	}

	useEffect(() => {
		if (games) {
			let gameMain = games
			if (gameMain === undefined) {
				history.push(makePath(history.location.pathname))
			} else {
				let filteredItems = (gameMain as any).filter(
					(item: any) => (
						item.gameName && item.gameName.toLowerCase().includes(filterText.toLowerCase())
					),
				);

				setConfigGames(filteredItems);
			}
		} else {
			history.push(makePath(history.location.pathname))
		}
	}, [games, filterText]) //eslint-disable-line

	return (
		<>
			{contextHolder}
			<div className="amb-games-container">

				<div className="amb-games-content">

					{/* games */}
					{loadingProvider ? (
						<div className="ambbet-provider-skeleton-loading">
							{
								Array.from({ length: 24 }, (_, index) => (
									<div className="skeleton-body" key={index}>
										<div className="skeleton" />
									</div>
								))
							}
						</div>
					) : (
						<div style={{ flex: 'auto' }}>
							<div>
								<div className="ambking-games-topbar" ref={ambkTopbarRef}>
									<Space size={0}>
										<Title level={5} className="title-provider" style={{ cursor: 'pointer' }}>{``}</Title>
									</Space>
									<div className="search-games">
										<Input
											placeholder={`ค้นหาเกม`}
											onChange={(e: any) => setFilterText(e.target.value)}
											prefix={<SearchOutlined rev={undefined} style={{ fontSize: 18 }} />}
											style={{ padding: '8px 12px' }}
										/>
									</div>
								</div>
							</div>
							{(configGames && configGames.length > 0 ? (
								<div className="ambking-grid-row">
									{configGames.map((game: any, key: number) => (
										<div key={`game_${key}_${game._id}`} className={`ambking-grid-col ${game._id}`}>
											{pathName === "launch-game" ? (
												<>
													<Link to="/" href="#pg-login" className={`game-card-square`} onClick={triggerSlotLogin(game.gameCode, game.productCode)} key={game._id}>
														<img src={game.imgUrl} alt={`${game._id}-${game.gameName}`} className="game-card-square-img" />
														<div className="game-card-square-name">{game.gameName}</div>
													</Link>
												</>
											) : (pathName === "home" && (
												<>
													<Link to={makePath('/login')} key={game._id} className={`game-card-square`}>
														<img src={game.imgUrl} alt={`${game._id}-${game.gameName}`} className="game-card-square-img" />
														<div className="game-card-square-name">{game.gameName}</div>
													</Link>
												</>
											))}
										</div>
									))}
								</div>
							)
								:
								<div style={{ display: 'flex', width: '100%' }}>
									<Empty
										description='ไม่พบเกมส์'
										image="/icons/new/empty-box.svg"
										imageStyle={{ height: 88 }}
										style={{ flex: 'auto', marginTop: 24 }}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default GameList
