import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { makePath } from '../../helpers/path'
import { Prefix } from '../../@types/prefix'
import { useLocation } from 'react-router'
// import { useLocation } from 'react-router'

interface BottomBarProps {
	prefix?: Prefix
	showMenuSupport?: boolean
	alert: () => void
	modalPromotions: () => void
	modalSupport: () => void
}
const BottomBar: FC<BottomBarProps> = ({ prefix, showMenuSupport, alert, modalPromotions, modalSupport }) => {
	// const history = useHistory()
	const loc = useLocation()

	return (
		// <div className="bottombar-mobile">
		// 	<ul className="nav-menu">
		// 		<li className="nav-item">
		// 			<Link
		// 				// onClick={alert}
		// 				to={makePath('/home')}
		// 				className={`nav-link ${loc.pathname === '/home' && 'active'}`}
		// 			>
		// 				<img src={`/icons/new/home.svg`} alt="icon" />
		// 				<span>หน้าหลัก</span>
		// 			</Link>
		// 		</li>
		// 		<li className="nav-item">
		// 			<Link
		// 				to={makePath('/login')}
		// 				// onClick={modalPromotions}
		// 				className={`nav-link`}
		// 			>
		// 				<img src={`/icons/new/promotion.svg`} alt="icon" />
		// 				<span>โปรโมชั่น</span>
		// 			</Link>
		// 		</li>
		// 		<li className="nav-item middle-item">
		// 			<Link
		// 				to={makePath('/login')}
		// 				className={`nav-link`}
		// 			>
		// 				<div className="icon-button-container">
		// 					<div className="icon-button">
		// 						<div className="icon-container" />
		// 					</div>
		// 					<img className="icon-center" src={`/icons/new/play_new.png`} alt="icon" />
		// 				</div>
		// 				<span>เข้าสู่ระบบ</span>
		// 			</Link>
		// 		</li>
		// 		<li className="nav-item">
		// 			<Link
		// 				to={(!prefix?.functions.hasOwnProperty("offRegister") || !prefix?.functions['offRegister']) ? makePath('/register') : makePath('/login')}
		// 				className={`nav-link`}
		// 			>
		// 				<img src={`/icons/new/account.svg`} alt="icon" />
		// 				<span>สมัครสมาชิก</span>
		// 			</Link>
		// 		</li>
		// 		{/* check link support */}
		// {!showMenuSupport && (
		// 	<li className="nav-item">
		// 		<Link
		// 			to="#"
		// 			onClick={modalSupport}
		// 			className={`nav-link`}
		// 		>
		// 			<img src={`/icons/new/help.svg`} alt="icon" />
		// 			<span>ติดต่อเรา</span>
		// 		</Link>
		// 	</li>
		// )}
		// {prefix?.lineLink && showMenuSupport ? (
		// 	<li className="nav-item">
		// 		<Link
		// 			to={{ pathname: prefix.lineLink }}
		// 			target="_blank"
		// 			className={`nav-link`}
		// 		>
		// 			<img src={`/icons/new/help.svg`} alt="icon" />
		// 			<span>ติดต่อเรา</span>
		// 		</Link>
		// 	</li>
		// ) : (!prefix?.lineLink && showMenuSupport &&
		// 	<li className="nav-item">
		// 		<Link
		// 			to={makePath('/login')}
		// 			className={`nav-link`}
		// 		>
		// 			<img src={`/icons/new/help.svg`} alt="icon" />
		// 			<span>ติดต่อเรา</span>
		// 		</Link>
		// 	</li>
		// )}
		// 	</ul>
		// </div>
		<div className="bottombar-mobile">
			<div className="bg-wrapper">
				<div className="bg-gradient-wrapper"></div>
				<svg width="112" height="64" viewBox="0 0 112 64" fill={prefix?.themeColor?.secondary} xmlns="http://www.w3.org/2000/svg">
					<g clipPath="url(#clip0_6129_102583)">
						<path d="M0 0C8.06271 0 15.5318 3.23866 19.6663 10.1606L21.366 13.0061C37.3247 39.7235 76.4234 38.6903 91.287 11.3484C95.408 3.76785 103.372 0 112 0V79H57.5H0V0Z" fill="var(--footer)"></path>
						<path d="M0 0C8.06271 0 15.5318 4.23866 19.6663 11.1606L21.366 14.0061C37.3247 40.7235 76.4234 39.6903 91.287 12.3484C95.408 4.76785 103.372 0 112 0V80H57.5H0V0Z" fill="url(#paint0_linear_6129_102583)"></path>
					</g>
					<defs><linearGradient id="paint0_linear_6129_102583" x1="56" y1="0" x2="56" y2="64" gradientUnits="userSpaceOnUse">
						<stop stopColor="var(--color-primary-end)"></stop>
						<stop offset="1" stopColor="var(--color-primary-start)"></stop>
					</linearGradient>
						<clipPath id="clip0_6129_102583">
							<rect width="112" height="64" fill="white"></rect>
						</clipPath>
					</defs>
				</svg>
				<div className="bg-gradient-wrapper"></div>
			</div>

			<div className="navigate-wrapper">
				<div className="navigate-container">
					<Link to={makePath('/home')} className={`navigate-item ${loc.pathname === '/home' ? 'active' : ''}`}>
						<i className="fa-solid fa-house"></i>
						<span>หน้าแรก</span>
					</Link>
					<Link to={makePath('/login')} className="navigate-item">
						<i className="fa-solid fa-gift"></i>
						<span>โปรโมชั่น</span>
					</Link>
					<Link to={makePath('/login')} className="navigate-item">
						<div className="navigate-main-icon">
							{prefix?.prefix === "PGAWIN" ?
								<img src="https://i.ibb.co/8d4hx3T/icon.png" alt="icon" style={{ width: 56, animation: 'dance 1s infinite' }} /> :
								prefix?.playgameImage ?
									<img className="icon-center" src={prefix?.playgameImage} alt="icon" style={{ width: 56, animation: 'dance 1s infinite' }} /> :
									<i className="fa-solid fa-gamepad-modern"></i>
							}
						</div>
						<span>เข้าสู่ระบบ</span>
					</Link>
					<Link to={makePath('/register')} className="navigate-item">
						<i className="fa-solid fa-user"></i>
						<span>สมัครสมาชิก</span>
					</Link>
					{!showMenuSupport && (
						<div onClick={modalSupport} className="navigate-item">
							<i className="fa-solid fa-headset"></i>
							<span>ติดต่อเรา</span>
						</div>
					)}
					{prefix?.lineLink && showMenuSupport ? (
						<Link to={{ pathname: prefix.lineLink }} target="_blank" className="navigate-item">
							<i className="fa-solid fa-headset"></i>
							<span>ติดต่อเรา</span>
						</Link>
					) : (!prefix?.lineLink && showMenuSupport &&
						<Link to={makePath('/login')} className="navigate-item">
							<i className="fa-solid fa-headset"></i>
							<span>ติดต่อเรา</span>
						</Link>
					)}
				</div>
			</div>
		</div>
	)
}

export default BottomBar
