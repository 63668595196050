import React, { FC, MouseEvent, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { AMBKingLoginResponse, AMBKingConfig, AMBKingGameProviderGames } from '../../../entities/AMBKing'
import api from '../../../helpers/api'
import { isMobile } from '../../../helpers/utils'
import { Button, Empty, Input, Space, Typography, message } from 'antd'
import { findCategoryGame, findCategoryLotto } from '../../../utils/subCategoryGame'
import { SearchOutlined } from '@ant-design/icons';
import { Link, useLocation, useHistory } from 'react-router-dom'
import { makePath } from '../../../helpers/path'
import { mergedGameTypeFav } from '../../../utils/funcAMBKing'
import { format } from '../../../helpers/number'

const { Title } = Typography

interface ShowGameProps {
	games: AMBKingConfig | any
	provider: string
	gameType: string
	pathName: string

	setProvider: (name: string) => void
	setLoading: (e: boolean) => void
}

interface Favorites {
	[key: string]: string;
}

const ShowGame: FC<ShowGameProps> = ({ games, provider, gameType, pathName, setProvider, setLoading }) => {
	const location = useLocation();
	const history = useHistory()
	const [configGames, setConfigGames] = useState<AMBKingGameProviderGames | any>(undefined)
	const [filterText, setFilterText] = useState('')
	const [selectedSubCategories, setSelectedSubCategories] = useState<string[]>(['all']);
	const [categoryGame, setCategoryGame] = useState([]);
	const [loadingFav, setLoadingFav] = useState<boolean>(false);
	const [activeFavorites, setActiveFavorites] = useState<any>({});
	const [messageApi, contextHolder] = message.useMessage();
	const [useEffectExecuted, setUseEffectExecuted] = useState(false);

	const params = new URLSearchParams(location.search);
	const gameFilter = params.get('gameFilter');

	const triggerSportLogin = (gameID: string, providerGame: string, gType: string) => async (e: MouseEvent<any>) => {
		e.preventDefault()

		setLoading(true)
		try {
			// ----- เวลา login เข้าเล่นเกม ambking ใช้เส้นนี้นะครับ
			const res = await api.post<void, AMBKingLoginResponse>('/ambking/login', {
				tab: gameType === 'favorite' ? gType : gameType,
				gameID: gameID,
				isMobile: isMobile(),
				provider: providerGame,
				redirectURL: btoa(history.location.pathname + history.location.search)
			})
			
			// window.location.href = 'play?game=' + btoa(res.uri)
			if (gameType === 'casino' || gameType === 'sport' || gameType === 'lotto' || provider === 'JOK' || provider === 'NJK') {
				window.location.href = res.uri
			} else {
				history.push(makePath('play?game=' + btoa(res.uri) + '&path=' + btoa(history.location.pathname + history.location.search)))
			}
			setTimeout(() => {
				setLoading(false)
			}, 2000);
		} catch ({ message }: any) {
			toast.error(message as string)
			setLoading(false)
		}
	}

	const handleSubCategoryClick = (type: string) => {
		const params = new URLSearchParams(location.search);

		if (type === 'all') {
			setSelectedSubCategories(['all']);
			params.set('gameFilter', 'all');
			history.push({ search: params.toString() });
		} else {
			const updatedTypes = selectedSubCategories.includes(type)
				? selectedSubCategories.filter((t) => t !== type)
				: [...selectedSubCategories, type];

			// ถ้ามีการเลือกประเภทอื่น ๆ ให้ in-active "ทั้งหมด"
			if (updatedTypes.length > 1 && updatedTypes.includes('all')) {
				const filteredTypes = updatedTypes.filter((t) => t !== 'all');
				setSelectedSubCategories(filteredTypes);
				params.set('gameFilter', filteredTypes.join(', '));
				history.push({ search: params.toString() });
			} else {
				setSelectedSubCategories(updatedTypes);
				params.set('gameFilter', updatedTypes.join(', '));
				history.push({ search: params.toString() });
			}
		}
	};

	const getFavorite = async () => {
		try {
			const res = await api.get<void, any>('/ambking/favourites');

			setActiveFavorites((prevActiveFavorites: Favorites) => {
				const newFavorites = { ...prevActiveFavorites };

				res.games.forEach((item: any) => {
					newFavorites[item.id] = item.id;
				});

				return newFavorites;
			});
		} catch (error) {
			toast.error(`Error fetching favorites: ${error}`)
			// console.error('Error fetching favorites:', error);
		}
	}

	const handleFavorite = async (gameId: string) => {

		const isGameInFavorites = gameId in activeFavorites;

		setActiveFavorites((prevFavorites: Favorites) => ({
			...prevFavorites,
			[gameId]: gameId,
		}));

		if (isGameInFavorites) {
			const { [gameId]: removedGameId, ...newFavorites } = activeFavorites;
			setActiveFavorites(newFavorites);
		}

		try {
			if (isGameInFavorites) {
				const res = await api.delete<void, any>(`/ambking/favourites/${gameId}`)
				// toast.error(res.message as string)
				messageApi.open({
					type: 'success',
					content: res.message as string,
				});
			} else {
				const res = await api.post<void, any>(`/ambking/favourites/${gameId}`)
				// toast.success(res.message as string)
				messageApi.open({
					type: 'success',
					content: res.message as string,
				});
			}
		} catch (error) {
			// console.error('Error sending API request:', error);
			messageApi.open({
				type: 'error',
				content: `Error sending API request: ${error}`,
			});
		}
	};

	useEffect(() => {
		if (pathName === "launch-game") {
			getFavorite();
		}
	}, []) //eslint-disable-line

	useEffect(() => {
		if (selectedSubCategories.length === 0) {
			setSelectedSubCategories(['all']);
		}
	}, [selectedSubCategories])

	useEffect(() => {
		// window.scrollTo(0, 0)
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [])

	useEffect(() => {
		if (games && gameType && gameType !== 'favorite') {

			// let gameMain = games
			const providerGame = gameType === 'sport' ? 'sport_game' : (gameType === 'lotto' ? 'lotto_game' : "")

			setCategoryGame(games[0] ? games[0].gameType : '')
			if (gameType === 'sport') {
				setConfigGames(games.find((game: any) => game.provider === providerGame))
			}

			if (gameType === 'lotto') {
				if (games.length > 0) {
					let filteredItems = games.find((game: any) => game.provider === providerGame).games.filter(
						(item: any) => (
							(selectedSubCategories.includes('all') ?
								(item.gameName && item.gameName.toLowerCase().includes(filterText.toLowerCase()))
								:
								(item.gameType && selectedSubCategories.some(category => item.gameType.includes(category))) &&
								(item.gameName && item.gameName.toLowerCase().includes(filterText.toLowerCase()))
							)
						),
					);
					setConfigGames({ games: filteredItems });
				} else {
					setConfigGames({ games: [] });
				}
			}
		}
	}, [games, provider, gameType, filterText, selectedSubCategories])

	useEffect(() => {
		if (gameType && gameType === 'favorite') {
			setLoadingFav(true)
			try {
				api.get<void, any>('/ambking/favourites').then((res) => {
					let gameMain = res.games
					const gameTypeFav: any = mergedGameTypeFav(gameMain)
					setCategoryGame(gameTypeFav);

					let filteredItems = gameMain.filter(
						(item: any) => (
							(selectedSubCategories.includes('all') ?
								(item.gameName && item.gameName.toLowerCase().includes(filterText.toLowerCase()))
								:
								(item.gameType && selectedSubCategories.some(category => item.gameType.includes(category))) &&
								(item.gameName && item.gameName.toLowerCase().includes(filterText.toLowerCase()))
							)
						),
					);

					setConfigGames({ games: filteredItems });
				})
			} catch (error) {
				toast.error(`Error fetching favorites: ${error}`)
				setTimeout(() => {
					setLoadingFav(false)
				}, 1000);
			} finally {
				setTimeout(() => {
					setLoadingFav(false)
				}, 1000);
			}
		}
	}, [gameType, filterText, selectedSubCategories])

	useEffect(() => {
		if (!useEffectExecuted) {

			const gameFilterSearch = gameFilter?.split(', ')
			if (gameFilterSearch) {
				if (gameFilterSearch.length > 0) {
					setSelectedSubCategories(gameFilterSearch);
				}
			}

			setUseEffectExecuted(true);
		}
	}, [gameFilter, provider, selectedSubCategories, useEffectExecuted]);

	return (
		<>
			{contextHolder}
			{(gameType === 'lotto' || gameType === 'favorite') && (configGames && configGames !== undefined && configGames.games.length > 0) &&
				<div className="amb-games-filter">
					<Button
						key={`all`}
						size='small'
						onClick={() => handleSubCategoryClick('all')}
						className={selectedSubCategories.includes('all') ? 'active' : ''}
					>
						{findCategoryLotto('all')}
					</Button>
					{categoryGame && categoryGame.length > 0 &&
						categoryGame.map((subCategory) => (
							<Button
								key={`filrer-${subCategory}`}
								size='small'
								onClick={() => handleSubCategoryClick(subCategory)}
								className={selectedSubCategories.includes(subCategory) ? 'active' : ''}
							>
								{gameType === 'lotto' && findCategoryLotto(subCategory)}
								{gameType === 'favorite' && findCategoryGame(subCategory)}
							</Button>
						))
					}
				</div>
			}
			<div style={{ flex: 'auto' }}>
				{(gameType === 'lotto' || gameType === 'favorite') && configGames && configGames !== undefined && configGames.games.length > 0 &&
					<div>
						<div className="ambking-games-topbar">
							<Space size={0}>
								<Title level={5} className="title-provider" onClick={() => setProvider('')} style={{ cursor: 'pointer' }}>{``}</Title>
							</Space>
							<div className="search-games">
								<Input
									placeholder={`ค้นหาเกม ใน ${gameType === 'lotto' ? `Lotto` : `เกมโปรด`}`}
									onChange={(e: any) => setFilterText(e.target.value)}
									prefix={<SearchOutlined rev={undefined} style={{ fontSize: 18 }} />}
									style={{ padding: '8px 12px' }}
								/>
							</div>
						</div>
					</div>
				}
				{(gameType !== 'favorite') ?
					(
						(configGames && configGames !== undefined && configGames.games.length > 0) ?
							(
								<div className="ambking-grid-row">
									{Object(configGames).games.map((game: any, key: number) => (
										<div className="games-grid-col" key={`game_${game.provider}_${key}_${game.id}`}>
											{pathName === 'launch-game' ? (
												<a href="#amb-king" className='game-card' onClick={triggerSportLogin(game.id, game.provider, gameType)} key={game.id} >
													<div className="game-card-img-container" />
													{game.image.vertical ? (
														<img src={game.image.vertical} alt={game.gameName} className="game-card-img" />
													) : (gameType === "sport" ? (
														<img src={`ambking/sport/${game.gameName.toLowerCase()}.png`} alt={game.gameName} className="game-card-img" />
													) : (
														<img src={`ambking/lotto/${game.id}.png`} alt={game.gameName} className="game-card-img" />
													)
													)}
													<div className="game-card-name">{game.gameName}</div>
												</a>
											) : (pathName === "home") && (
												<Link className='game-card' to={makePath('/login')} key={game.id} >
													<div className="game-card-img-container" />
													{game.image.vertical ? (
														<img src={game.image.vertical} alt={game.gameName} className="game-card-img" />
													) : (gameType === "sport" ? (
														<img src={`ambking/sport/${game.gameName.toLowerCase()}.png`} alt={game.gameName} className="game-card-img" />
													)
														: (
															<img src={`ambking/lotto/${game.id}.png`} alt={game.gameName} className="game-card-img" />
														)
													)}
													<div className="game-card-name">{game.gameName}</div>
												</Link>
											)}
										</div>
									))}
								</div>
							)
							:
							<div style={{ display: 'flex', width: '100%' }}>
								<Empty
									description='ไม่พบเกมส์'
									image="/icons/new/empty-box.svg"
									imageStyle={{ height: 88 }}
									style={{ flex: 'auto', marginTop: 24 }}
								/>
							</div>
					) : (
						loadingFav ?
							<div className="ambbet-provider-skeleton-loading">
								{
									Array.from({ length: 24 }, (_, index) => (
										<div className="skeleton-body" key={index}>
											<div className="skeleton" />
										</div>
									))
								}
							</div>
							:
							(configGames && configGames !== undefined && configGames.games.length > 0) ?
								(
									<div className="ambking-grid-row">
										{Object(configGames).games.map((game: any, key: number) => (
											<div className="ambking-grid-col" key={`game_${game.provider}_${key}_${game.id}`}>
												{pathName === 'launch-game' ? (
													<>
														<a href="#amb-king" className='game-card' onClick={triggerSportLogin(game.id, game.provider, game.gameCategory)} key={game.id} >
															<div className="game-card-img-container" />
															<img src={game.image.vertical} alt={game.gameName} className="game-card-img" />
															<div className="game-card-name">{game.gameName}</div>
															{game.rtp > 0 && (
																<div className="ambking-grid-col-rtp">
																	<span>RTP</span> {format(game.rtp, 2)}%
																</div>
															)}
														</a>
														<div className={`ambking-grid-col-fav ${activeFavorites[game.id] ? 'active' : ''}`} onClick={() => handleFavorite(game.id)}>
															<img src="/icons/3d/favorite.svg" alt="fav icon" />
														</div>
													</>
												) : (pathName === "home") && (
													<>
														<Link className='game-card' to={makePath('/login')} key={game.id} >
															<div className="game-card-img-container" />
															<img src={game.image.vertical} alt={game.gameName} className="game-card-img" />
															<div className="game-card-name">{game.gameName}</div>
														</Link>
														<div className={`ambking-grid-col-fav`}>
															<img src="/icons/3d/favorite.svg" alt="fav icon" />
														</div>
													</>
												)}
											</div>
										))}
									</div>
								)
								:
								<div style={{ display: 'flex', width: '100%' }}>
									<Empty
										description='ไม่พบเกมส์'
										image="/icons/new/empty-box.svg"
										imageStyle={{ height: 88 }}
										style={{ flex: 'auto', marginTop: 24 }}
									/>
								</div>
					)
				}
			</div>
		</>
	)
}

export default ShowGame
