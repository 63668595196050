
import { useEffect } from "react";
import { theme } from 'antd';
import { hexToHsl } from '../helpers/hexToHsl';
import { ApplicationState } from '../store';
import { useSelector } from "react-redux";
import { getDomainWithoutSubdomain } from "./domainWithoutSubdomain";

const { useToken } = theme;

export default function SetTheme() {
    const { token } = useToken();
    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    // const themeSetting = Themes.filter(item => item.key === prefixState?.info?.themeID)[0].other.colorSecondary
    const colorPrimaryStart = prefixState?.info?.themeColor?.primaryStart || '#005ce5';
    const colorPrimaryEnd = prefixState?.info?.themeColor?.primaryEnd || '#ad7efe';
    const colorSecondary = prefixState?.info?.themeColor?.secondary || '#ffc800';
    const colorTertiary = prefixState?.info?.themeColor?.tertiary || '#101010';
    
    const getContrast = (hexcolor: string, invert: boolean = false) => {
        // If a leading # is provided, remove it
        if (hexcolor.slice(0, 1) === '#') {
            hexcolor = hexcolor.slice(1);
        }

        // If a three-character hexcode, make six-character
        if (hexcolor.length === 3) {
            hexcolor = hexcolor.split('').map(function (hex) {
                return hex + hex;
            }).join('');
        }

        // Convert to RGB value
        let r = parseInt(hexcolor.substr(0, 2), 16);
        let g = parseInt(hexcolor.substr(2, 2), 16);
        let b = parseInt(hexcolor.substr(4, 2), 16);

        // Get YIQ ratio
        let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

        // Check contrast
        return invert ? (yiq >= 128) ? '#ffffff' : '#000000' : (yiq >= 128) ? '#000000' : '#ffffff';
    }

    useEffect(() => {
        const rootStyle = document.documentElement.style

        if (token) {
            rootStyle.setProperty('--color-primary', token.colorPrimary)
            rootStyle.setProperty('--color-primary-hsl', `${hexToHsl(token.colorPrimary).h}, ${hexToHsl(token.colorPrimary).s}%, ${hexToHsl(token.colorPrimary).l}%`)
            rootStyle.setProperty('--color-primary-h', `${hexToHsl(token.colorPrimary).h}`)
            rootStyle.setProperty('--color-primary-s', `${hexToHsl(token.colorPrimary).s}%`)
            rootStyle.setProperty('--color-primary-l', `${hexToHsl(token.colorPrimary).l}%`)
            rootStyle.setProperty('--color-primary-start', colorPrimaryStart)
            rootStyle.setProperty('--color-primary-end', colorPrimaryEnd)
            rootStyle.setProperty('--color-text-primary-bg', `${getContrast(colorPrimaryStart)}`)
            rootStyle.setProperty('--color-text-shadow-primary-bg', `${getContrast(colorPrimaryStart, true)}4d`)
            rootStyle.setProperty('--color-secondary', colorSecondary)
            rootStyle.setProperty('--color-secondary-hsl', `${hexToHsl(colorSecondary).h}, ${hexToHsl(colorSecondary).s}%, ${hexToHsl(colorSecondary).l}%`)
            rootStyle.setProperty('--color-secondary-h', `${hexToHsl(colorSecondary).h}`)
            rootStyle.setProperty('--color-secondary-s', `${hexToHsl(colorSecondary).s}%`)
            rootStyle.setProperty('--color-secondary-l', `${hexToHsl(colorSecondary).l}%`)
            rootStyle.setProperty('--color-text-secondary-bg', `${getContrast(colorSecondary)}`)
            rootStyle.setProperty('--color-text-shadow-secondary-bg', `${getContrast(colorSecondary, true)}4d`)
            rootStyle.setProperty('--color-tertiary', colorTertiary)
            rootStyle.setProperty('--color-tertiary-hsl', `${hexToHsl(colorTertiary).h}, ${hexToHsl(colorTertiary).s}%, ${hexToHsl(colorTertiary).l}%`)
            rootStyle.setProperty('--color-tertiary-h', `${hexToHsl(colorTertiary).h}`)
            rootStyle.setProperty('--color-tertiary-s', `${hexToHsl(colorTertiary).s}%`)
            rootStyle.setProperty('--color-tertiary-l', `${hexToHsl(colorTertiary).l}%`)
            rootStyle.setProperty('--color-text-tertiary-bg', `${getContrast(colorTertiary)}`)
            rootStyle.setProperty('--color-text-shadow-tertiary-bg', `${getContrast(colorTertiary, true)}4d`)
            rootStyle.setProperty('--color-link', token.colorPrimary)
            rootStyle.setProperty('--form-card-bg', `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='202' height='151' viewBox='0 0 202 151' fill='none'%3E%3Ccircle opacity='0.1' cx='129.5' cy='72.5' r='72.5' fill='url(%23paint0_linear)'/%3E%3Ccircle opacity='0.1' cx='63' cy='88' r='63' fill='url(%23paint1_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='136.5' y1='2.63738e-07' x2='140' y2='119' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23${token.colorPrimary.substring(1)}'/%3E%3Cstop offset='0.583333' stop-color='%23${token.colorPrimary.substring(1)}' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23${token.colorPrimary.substring(1)}' stop-opacity='0'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear' x1='63' y1='25' x2='63' y2='151' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23${token.colorPrimary.substring(1)}'/%3E%3Cstop offset='0.583333' stop-color='%23${token.colorPrimary.substring(1)}' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23FFCD18' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E") no-repeat top 20px right 20px`)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useEffect(() => {
        const loadBackgroundImage = () => {
            if (document.querySelectorAll(".bg-main").length <= 0) {
                const div = document.createElement('div');
                const img = document.createElement('img');

                if (window.location.hostname === 'localhost') {
                    img.src = prefixState?.info?.bgImage !== "" ? prefixState?.info?.bgImage : 'https://pub-7d1da7fbc6504d9f8100764b1195e0fc.r2.dev/assets/bg-default.webp'
                } else {
                    img.src = prefixState?.info?.bgImage !== "" ? prefixState?.info?.bgImage : `https://cdn.${getDomainWithoutSubdomain(window.location.host)}/assets/bg-default.webp`;
                }

                div.className = 'bg-main';
                document.body.insertBefore(div, document.body.firstChild);
                div.appendChild(img);
            }
        };

        loadBackgroundImage();
    }, [prefixState?.info?.enableBlurBg, prefixState?.info?.bgImage]);

    // useMemo(() => {
    //     console.log("timeid memo", time_nanoid);
    //     const timeidLocal = localStorage.getItem('timeid')
    //     console.log("---->", timeid, timeidLocal);
    //     const iframe: any = document.createElement('iframe');
    //     console.log(iframe);

    //     if (iframe) {
    //         if (timeid !== timeidLocal) {
    //             const pathGoBack = localStorage.getItem('game-path')
    //             if (pathGoBack !== null) {
    //                 history.push(makePath(pathGoBack)) 
    //                 // return false
    //             }
    //         }
    //     }
    // useMemo(() => {
    //     var topbarElement = document.getElementsByClassName('topbar-game')[0];
    //     if (topbarElement) {
    //         topbarElement.remove();
    //     }
    // }, [nanoId])


    return null;
}

