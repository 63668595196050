import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BalanceWidget from '../components/Wallet/BalanceWidget'
import TurnWidget from '../components/Wallet/TurnWidget'
import CashbackWidget from '../components/Wallet/CashbackWidget'
import WalletMobileMenu from '../components/Wallet/WalletMobileMenu'
import WalletPromotionContainer from '../components/Wallet/WalletPromotionContainer'
import Popup from '../components/Popup'
import MainSlider from '../components/Wallet/MainSlider'
import { ApplicationState } from '../store'
import Footer from '../components/Layout/Footer'
import { requestUser } from '../store/user/user.actions'
import CheckInModal from '../components/CheckIn/CheckInModal'
// import { FloatButton } from 'antd'
// import { UpOutlined } from '@ant-design/icons';
// import ButtonFooter from '../components/Layout/ButtonFooter'

const Wallet = () => {
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const userState = useSelector((state: ApplicationState) => state.user)
	const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [textPromotion, setTextPromotion] = useState("")

	const dispatch = useDispatch()

	const refetchCredit = async () => {
		setIsLoading(false)
		dispatch(requestUser())
		setTimeout(() => {
			setIsLoading(true)
		}, 1000);
	}

	useEffect(() => {
		if (prefixState.info) {
			const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
				if (value === "") {
					return true;
				}
				return false;
			}) : true;
			setShowMenuSupport(isNullishSocialMedia)
		}
	}, [prefixState])

	useEffect(() => {
		const handleTextMyPromotion = () => {
			if (!userState?.info?.bonusAvailable) {
				return setTextPromotion(`รับโบนัส`)
			}

			if (!userState?.info?.redeemAvailable) {
				return setTextPromotion("รับโค้ดคูปอง")
			}

			if (!userState?.info?.wheelAvailable) {
				return setTextPromotion("รับกงล้อ")
			}

			if (!userState?.info?.affiliateAvailable) {
				return setTextPromotion("รับแนะนำเพื่อน")
			}

			if (!userState?.info?.cashbackAvailable) {
				return setTextPromotion("รับแคชแบ็ก")
			}

			if (!userState?.info?.checkinAvailable) {
				return setTextPromotion("รับเช็คอิน")
			}

			return setTextPromotion("ไม่รับโบนัส")
		}

		handleTextMyPromotion()
	}, []);

	return (
		<>

			{/* <div className="ambking-container"> */}
			<MainSlider prefixState={prefixState} />
			{/* </div> */}
			<div className="wallet-row">
				<div className="wallet-col">
					<BalanceWidget
						isTurnover={prefixState.info?.isTurnover || false}
						creditOutStanding={userState.creditOutStanding}
						credit={userState.credit}
						totalTurn={userState.info?.totalTurn || 0}
						currentTurnover={userState.currentTurnover}
						isCashback={userState.cashback.enable}
					/>
				</div>
				<div className="wallet-col">
					<div className="balance-other">
						{/* <TurnWidget
							isTurnover={prefixState.info?.isTurnover || false}
							credit={userState.credit}
							totalTurn={userState.info?.totalTurn || 0}
							currentTurnover={userState.currentTurnover}
							turnover={userState.turnover}
							showTurnover={userState.showTurnover || false}
							refetchCredit={refetchCredit}
							isLoading={isLoading}
							textPromotion={textPromotion}
						/> */}
						{userState.cashback.enable && <CashbackWidget cashback={userState.cashback.total} />}
					</div>
				</div>
			</div>

			{/* Mobile menu */}
			<WalletMobileMenu
				lineLink={prefixState.info?.lineLink || ''}
				checkin={prefixState.info?.checkin.enable || false}
				wheel={prefixState.info?.wheel.enable || false}
				commission={prefixState.info?.commission || false}
				showMenuSupport={showMenuSupport}
				prefixState={prefixState}
				ranking={prefixState.info?.functions.rank || false}
				bonusFreeStatus={prefixState.info?.bonusFreeStatus || false}
			/>

			{/* Promotion */}
			{(prefixState.info?.promotions.length || 0) > 0 && (
				<WalletPromotionContainer promotions={prefixState.info?.promotions} />
			)}

			{/* Popup */}
			<Popup />
			{/* CheckIn */}
			{prefixState.info?.checkin.enable && !prefixState.info?.checkin.pupupStartDisable && <CheckInModal />}

			<Footer prefixState={prefixState} />
		</>
	)
}

export default Wallet
