// import { useFormik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { APIResponse } from '../../@types/api'
import api from '../../helpers/api'
import { requestUser } from '../../store/user/user.actions'
import { Button, Form, InputNumber, Typography } from 'antd'
import { ApplicationState } from '../../store'
import VerifyPhone from '../VerifyPhone/VerifyPhone'
import { InfoCircleOutlined } from '@ant-design/icons';
import { makePath } from '../../helpers/path'
interface WithdrawFormProps {
	initAmount: number
	minWithdraw?: number
	paymentGateway: string
}
const WithdrawForm: FC<WithdrawFormProps> = ({ initAmount, minWithdraw, paymentGateway }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch()
	const history = useHistory()

	const [isSubmitting, setSubmitting] = useState<boolean>(false)

	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const userState = useSelector((state: ApplicationState) => state.user)
	const prefix = prefixState.info, user = userState.info

	const [openVerifyPhone, setOpenVerifyPhone] = useState<boolean>(false)

	const handleSubmit = async () => {
		form.validateFields()
			.then(async (values: any) => {
				if (userState.info && prefixState.info) {
					values.paymentGateway = paymentGateway ? paymentGateway : 'bank'
					if (!userState.info.phoneVerified && prefixState.info.functions.otp_withdraw) {
						setOpenVerifyPhone(true)
					} else {
						setSubmitting(true)
						try {
							const res = await api.post<void, APIResponse>(
								'/member/withdraw',
								values
							)
							dispatch(requestUser())
							toast.success(res.message)
							history.push(makePath('/transactions?tab=withdraw'))
						} catch (error: any) {
							toast.error(error.message as string)
						} finally {
							setSubmitting(false)
						}
					}
				}
			}).catch((errorInfo) => { });
	}

	useEffect(() => {
		if (form) {
			form.setFieldsValue({
				'amount': initAmount
			})
		}
	}, [form]) //eslint-disable-line

	const textWithdarw = `ยอดถอน ${userState.vizpayWithdrawSetting !== undefined && userState.vizpayWithdrawSetting.displayFeeMemberStatus ? `(*มีค่าธรรมเนียมในการถอนครั้งนี้)` : ``}`

	return (
		<>
			<Form
				form={form}
				name="withdrawForm"
				layout="vertical"
				hideRequiredMark>
				<Form.Item
					name="amount"
					label={textWithdarw}
					rules={[{ required: true, message: 'กรุณากรอกจำนวนเงินที่ถอน' }]}
					style={{ marginBottom: 10 }}
					extra={`ยอดขั้นต่ำ ${minWithdraw} บาท`}
				>
					<InputNumber
						placeholder="ยอดถอน"
						inputMode="numeric"
						prefix="฿"
						controls={false}
						formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						style={{ width: '100%' }}
					/>
				</Form.Item>
				<div className="btn-submit">
					<Button type="primary" htmlType="submit" loading={isSubmitting} disabled={isSubmitting} onClick={handleSubmit} block>
						ยืนยันถอนเงิน
					</Button>
				</div>
			</Form>

			{openVerifyPhone && (
				<VerifyPhone
					phone={userState.info?.phone || ''}
					isOpen={openVerifyPhone}
					prefixInfo={prefixState.info}
					onClose={() => setOpenVerifyPhone(false)} />
			)}
		</>
	)
}

export default WithdrawForm
