import React, { FC, useEffect, useLayoutEffect, useState, useRef } from 'react'
import { Alert, Button, Divider, Form, Input, Modal, Typography } from 'antd'
import { PatternFormat } from 'react-number-format'
import { toast } from 'react-toastify'
import Turnstile from "react-turnstile";
import api from '../../helpers/api';
import { useDispatch } from 'react-redux';
import { requestUser } from '../../store/user/user.actions';

const { Text } = Typography

interface VerifyPhoneProps {
    isOpen: boolean
    onClose: () => void
    phone: string
    prefixInfo: any
}

const VerifyPhone: FC<VerifyPhoneProps> = ({ isOpen, onClose, phone, prefixInfo }) => {
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [resetCounter, setResetCounter] = useState(0);

    const dispatch = useDispatch()

    const [isSubmitting1, setSubmitting1] = useState<boolean>(false)
    const [isSubmitting2, setSubmitting2] = useState<boolean>(false)
    const [step, setStep] = useState<number>(1)
    const [tokenTurnstile, setTokenTurnstile] = useState<string>('')
    const [dataVerifyOTP, setDataVerifyOTP] = useState<any>()
    const OTP_Delay = 180

    const handleSubmit1 = async () => {
        form.validateFields()
            .then(async (values: any) => {
                setSubmitting1(true)
                try {
                    values.phone = values.phone.replace(/-|_/g, '')
                    if (values.phone.length !== 10) {
                        toast.error('เบอร์โทรศัพท์ต้องมีความยาว 10 ตัวอักษร')
                        return
                    }
                    if (tokenTurnstile === "") {
                        toast.error('กรุณารอ Turnstile โหลดเสร็จสิ้น')
                        setResetCounter((prevCounter) => prevCounter + 1);
                        return
                    }
                    const dataVerify = {
                        phone: values.phone,
                        captcha: tokenTurnstile,
                    }
                    const resVerify = await api.post('/member/verify-otp', dataVerify)

                    if (resVerify.status) {
                        setResetCounter((prevCounter) => prevCounter + 1);
                        setStep(2)
                        setDataVerifyOTP(resVerify)
                        setTimer(OTP_Delay);
                    }
                } catch (error: any) {
                    toast.error(error.message as string)
                    setResetCounter((prevCounter) => prevCounter + 1);
                } finally {
                    setSubmitting1(false)
                }
            }).catch((errorInfo) => { });
    }

    const handleSubmit2 = async () => {
        form2.validateFields()
            .then(async (values: any) => {
                values.phone = dataVerifyOTP.phone
                values.ref = dataVerifyOTP.ref
                values.transaction_id = dataVerifyOTP.transaction_id
                values.token = dataVerifyOTP.token
                setSubmitting2(true)
                try {
                    if (values.phone === "" || values.ref === "") {
                        toast.error('กรุณาทำรายการใหม่')
                        return
                    }
                    const resVerify: any = await api.post('/member/confirm-otp', values)

                    dispatch(requestUser())
                    toast.success(resVerify.message as string)
                    onClose()
                } catch (error: any) {
                    setOTP(["", "", "", "", ""])
                    toast.error(error.message as string)
                    setResetCounter((prevCounter) => prevCounter + 1);
                } finally {
                    setSubmitting2(false)
                }
            }).catch((errorInfo) => { });
    }

    // ---------- OTP FORM ------------- //
    const [otp, setOTP] = useState<string[]>(["", "", "", ""]);
    const inputRefs = useRef<any>([]);
    const [timer, setTimer] = useState(OTP_Delay);
    const [back, setBack] = useState<boolean>(false);

    const handleOTPInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        const { value } = e.target;
        const newOTP = [...otp];
        newOTP[index] = value;
        setOTP(newOTP);
        form2.setFieldsValue({ "otp": newOTP.join('') })

        if (value === "" && index > 0 && back === false) {
            inputRefs.current[index - 1]?.focus({
                cursor: 'all',
            });
        }

        if (value !== "") {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const keyCode = e.keyCode || e.which;
        const keyValue = String.fromCharCode(keyCode);

        if (!/^\d+$/.test(keyValue)) {
            e.preventDefault();
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        const keyCode = e.keyCode || e.which;
        const keyValue = String.fromCharCode(keyCode);

        if (e.key === 'Backspace' && e.currentTarget.value === '') {
            setBack(true)
            inputRefs.current[index - 1]?.focus({
                cursor: 'all',
            });
            e.preventDefault();
        } else {
            setBack(false)
        }

        if (keyValue === e.currentTarget.value) {
            inputRefs.current[index + 1]?.focus({
                cursor: 'all',
            });
        }
    };

    const handleVerifyRefOTP = async () => {
        setTimer(OTP_Delay);
        setResetCounter((prevCounter) => prevCounter + 1);
        try {
            if (tokenTurnstile === "") {
                toast.error('กรุณารอ Turnstile โหลดเสร็จสิ้น')
                setResetCounter((prevCounter) => prevCounter + 1);
                return
            }
            const dataVerify = {
                phone: phone,
                captcha: tokenTurnstile,
            }
            const resVerify = await api.post('/member/verify-otp', dataVerify)
            if (resVerify.status) {
                setDataVerifyOTP(resVerify)
            } else {
                toast.error('กรุณารอสักครู่แล้วลองใหม่อีกครั้ง')
            }
        } catch (error: any) {
            toast.error(error.message as string)
        } finally {

        }
    }

    useEffect(() => {
        const timerId = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 1) {
                    clearInterval(timerId);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);
        return () => clearInterval(timerId);
    }, [timer]);
    // ---------- OTP FORM ------------- //

    useEffect(() => {
        if (isOpen && form) {
            form.setFieldsValue({
                'phone': phone
            })
        }
    }, [isOpen, form, phone])

    useEffect(() => {
        if (isOpen && form2) {
            form2.resetFields()
        }
    }, [isOpen, form2])

    useLayoutEffect(() => {
        const timerId = setTimeout(() => {
            if (inputRefs.current[0]) {
                (inputRefs.current[0] as HTMLInputElement).focus();
            }
        }, 100); // 5000 milliseconds = 5 วินาที

        return () => clearTimeout(timerId);
    }, [])

    return (
        <Modal
            className="ant-modal-verify-phone"
            open={isOpen}
            title="ยืนยันเบอร์โทรศัพท์"
            centered
            onCancel={() => onClose()}
            closable={true}
            footer={null}
        >
            {step === 1 && (
                <Form
                    form={form}
                    name="VerifyPhoneForm1"
                    layout="vertical"
                    hideRequiredMark>
                    <Form.Item
                        name="phone"
                        label='เบอร์โทรศัพท์'
                        style={{ marginBottom: 0 }}
                        rules={[{ required: true, message: 'กรุณากรอกเบอร์โทรศัพท์' }]}>
                        <PatternFormat
                            customInput={Input}
                            format="###-###-####"
                            mask="_"
                            type="tel"
                            placeholder="เบอร์โทรศัพท์"
                        />
                    </Form.Item>
                    {prefixInfo.recaptcha.enable && (
                        <div style={{ marginTop: "10px" }}>
                            <Turnstile
                                key={resetCounter}
                                sitekey={prefixInfo.recaptcha.key}
                                theme="dark"
                                onVerify={(token) => setTokenTurnstile(token)}
                                retry='auto'
                                autoResetOnExpire={true}
                            />
                        </div>
                    )}
                    <Alert message="หากไม่ได้ยืนยันเบอร์โทรศัพท์ คุณจะไม่สามารถทำรายการถอนได้" type="warning" style={{ marginTop: "5px" }} />
                    <Button
                        type="primary"
                        shape="round"
                        htmlType="submit"
                        loading={isSubmitting1}
                        disabled={isSubmitting1}
                        onClick={handleSubmit1}
                        style={{ marginTop: 16, marginBottom: 16 }}
                        block
                    >
                        ขอ OTP
                    </Button>
                </Form>)}
            {step === 2 && (
                <Form
                    form={form2}
                    name="VerifyPhoneForm2"
                    layout="vertical"
                    hideRequiredMark>
                    <div className="register-title" style={{ textAlign: 'center' }}>
                        <Text type="secondary">กรอก OTP ที่ส่งไปยัง {phone && phone.replace(/(\d{3})\d{3}(\d{2})(\d{2})/, '$1*****$3')}</Text>
                    </div>
                    <div className="register-title" style={{ textAlign: 'center' }}>
                        <Text type="secondary">OTP Ref.{dataVerifyOTP?.ref}</Text>
                    </div>
                    <Divider />
                    <Form.Item
                        name="otp"
                        rules={[
                            { required: true, message: "กรุณากรอก OTP" },
                            { len: 4, message: "OTP ต้องกรอกให้ครบ 4 หลัก" },
                        ]}
                    >
                        <Input.Group className="otp-input-group">
                            {otp.map((digit, index) => (
                                <Input
                                    key={index}
                                    ref={(ref) => (inputRefs.current[index] = ref)}
                                    value={digit}
                                    maxLength={1}
                                    onChange={(e) => handleOTPInputChange(e, index)}
                                    onKeyPress={handleKeyPress}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                />
                            ))}
                        </Input.Group>
                    </Form.Item>
                    {prefixInfo.recaptcha.enable && (
                        <div style={{ marginTop: "10px" }}>
                            <Turnstile
                                key={resetCounter}
                                sitekey={prefixInfo.recaptcha.key}
                                theme="dark"
                                onVerify={(token) => setTokenTurnstile(token)}
                                retry='auto'
                                autoResetOnExpire={true}
                            />
                        </div>
                    )}
                    <Alert message="หากไม่ได้ยืนยันเบอร์โทรศัพท์ คุณจะไม่สามารถทำรายการถอนได้" type="warning" style={{ margin: "5px 0 24px" }} />
                    <Button
                        type="primary"
                        shape="round"
                        htmlType="submit"
                        loading={isSubmitting2}
                        disabled={isSubmitting2}
                        onClick={handleSubmit2}
                        block
                    >
                        ยืนยัน
                    </Button>
                    <Form.Item style={{ textAlign: 'center', marginBottom: 0 }}>
                        {timer <= 0 ? (
                            <Button type="link" className="btn-reset-password" size="small" onClick={handleVerifyRefOTP}>ขอรหัส OTP ใหม่</Button>
                        ) : (
                            <Button type="link" className="btn-reset-password" size="small" disabled>ขอรหัส OTP ใหม่ (รอ {timer} วินาที)</Button>
                        )}
                    </Form.Item>
                </Form>)}
        </Modal>
    )
}

export default VerifyPhone
